import { designStores } from 'stores/builder/design';
import { v4 as generateId } from 'uuid';
import { sample } from 'effector';

import { generateTemplatePages } from './helpers/generate-template-pages';
import { $allBlocks, $pagesIds, $pages } from './model';

sample({
    fn: ({ designInfo: { activeDesign }, allBlocks }) => generateTemplatePages(allBlocks, activeDesign),
    source: { designInfo: designStores.coverStyles, allBlocks: $allBlocks },
    target: $pages
});

sample({
    fn: pages => Object.keys(pages).map(_ => generateId()),
    target: $pagesIds,
    source: $pages
});
