import { type InputHTMLAttributes, type RefObject, useEffect, useState } from 'react';
import { ClickableWrapper } from 'components/wrappers/ClicableWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { DescriptionBadge } from 'components/common/DescriptionBadge';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { useToggle } from 'react-use';

import { InformationInputWrapper, StyledInputWrapper } from './styles';
import { HidePasswordIcon, ShowPasswordIcon } from './auth';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    refik?: RefObject<any>;
    value?: string;
}

export interface InputDecorationProps {
    type?: InputProps['type'];
    untouchedWarning?: string;
    defaultValue?: string;
    description?: string;
    placeholder?: string;
    subtitle?: string;
    title?: string;
}

export interface StyledTextInputProps
    extends Omit<InputProps, 'defaultChecked' | 'defaultValue'>,
        InputDecorationProps {
    autoComplete?: string;
    titleColor?: string;
    isTouched?: boolean;
    disabled?: boolean;
    error?: string;
    value?: string;
}

export const StyledTextInput = ({
    autoComplete,
    description,
    titleColor,
    isTouched,
    disabled,
    error,
    title,
    refik,
    type,
    ...inputProps
}: StyledTextInputProps) => {
    const [inputType, setInputType] = useState(type);
    const [showPassword, setShowPassword] = useToggle(false);
    const isPasswordType = type === 'password';

    const onChangePasswordView = () => {
        setInputType(showPassword ? 'password' : 'text');
        setShowPassword();
    };

    useEffect(() => setInputType(type), [type]);

    return (
        <Column width="100%" noWrap>
            {(error || title) && (
                <InputTitle color={titleColor} isError={!!error}>
                    {error || title || ''}
                    {description && <DescriptionBadge description={description} />}
                </InputTitle>
            )}
            <RelativeWrapper>
                <StyledInputWrapper
                    autoComplete={autoComplete}
                    disabled={disabled}
                    touched={isTouched}
                    type={inputType}
                    error={error}
                    ref={refik}
                    {...inputProps}
                />
                {isPasswordType && (
                    <InformationInputWrapper onClick={onChangePasswordView}>
                        <ClickableWrapper type="button">
                            {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                        </ClickableWrapper>
                    </InformationInputWrapper>
                )}
            </RelativeWrapper>
        </Column>
    );
};
