import {
    onlySimpleCharactersAllowedMessage,
    atLeastOneNumberRequiredMessage,
    incorrectMonthFieldCountMessage,
    incorrectDateFieldCountMessage,
    oneCapitalCharRequiredMessage,
    incorrectNumberMessage,
    incorrectMonthMessage,
    passwordLengthMessage,
    incorrectDateMessage,
    requiredFieldMessage,
    invalidEmailMessage,
    incorrectIdMessage,
    requiredSetMessage,
    exactLimitMessage
} from 'constants/messages';
import {
    onlySimpleCharactersAllowedRegExp,
    atLeastOneNumberRequiredRegExp,
    oneCapitalCharRequiredRegExp,
    onlySymbolAndNumbersRegExp,
    onlyNumberRegExp
} from 'constants/regularExpressions';
import addYears from 'date-fns/addYears';
import urlRegex from 'url-regex';
import * as Yup from 'yup';

export const passwordMinimum = 8;

export const yupNumber = Yup.string().matches(onlyNumberRegExp, incorrectNumberMessage).required(requiredFieldMessage);
export const yupDefault = Yup.string().required(requiredFieldMessage);
export const yupDefaultArray = Yup.array().of(yupDefault).required(requiredSetMessage);
export const yupDefaultConfirmed = Yup.boolean().required('You must confirm the conditions'); // .oneOf([true], 'You must confirm the conditions')

export const yupCompanyName = yupDefault.matches(onlySimpleCharactersAllowedRegExp, onlySimpleCharactersAllowedMessage);
export const yupId = yupDefault.min(4).matches(onlySymbolAndNumbersRegExp, incorrectIdMessage);
export const yupUsername = yupDefault;
export const yupUserLastName = yupDefault;
export const yupEmail = Yup.string().email(invalidEmailMessage).required(requiredFieldMessage);
export const yupEmailNoHint = yupDefault;
export const yupPassword = Yup.string()
    .required(requiredFieldMessage)
    .min(passwordMinimum, passwordLengthMessage(passwordMinimum))
    .matches(oneCapitalCharRequiredRegExp, oneCapitalCharRequiredMessage)
    .matches(atLeastOneNumberRequiredRegExp, atLeastOneNumberRequiredMessage);
export const yupRepeatPassword = (passwordName = 'password') =>
    Yup.string()
        .oneOf([Yup.ref(passwordName)], 'Passwords do not match')
        .required(requiredFieldMessage);
export const yupPasswordNoHint = yupDefault;
export const yupSecurityCode = yupDefault;
export const yupUrl = Yup.string().matches(urlRegex(), 'Please enter website');

export const yupWom = yupDefault;
export const yupCardName = yupDefault;
export const yupCardNumber = Yup.string()
    .min(16, exactLimitMessage(16))
    .max(16, exactLimitMessage(16))
    .required(requiredFieldMessage);

export const yupCvc = Yup.string()
    .matches(/[0-9]/, incorrectNumberMessage)
    .min(3, exactLimitMessage(3))
    .max(3, exactLimitMessage(3))
    .required(requiredFieldMessage);

export const yupExpireDate = Yup.string()
    .typeError(incorrectDateMessage)
    .max(4, incorrectDateFieldCountMessage)
    .matches(/[0-9]/, incorrectNumberMessage)
    .required(requiredFieldMessage)
    .test(incorrectDateMessage, incorrectDateMessage, expirationDate => {
        if (!expirationDate) return false;

        const numberExpirationDate = Number(expirationDate);
        const today = new Date();
        const yearToday = today.getFullYear();
        const maxValidYear = addYears(today, 8).getFullYear();

        return !(numberExpirationDate < yearToday || numberExpirationDate > maxValidYear);
    });

export const yupExpireMonth = Yup.string()
    .typeError(incorrectMonthMessage)
    .max(2, incorrectMonthFieldCountMessage)
    .matches(/[0-9]/, incorrectNumberMessage)
    .required(requiredFieldMessage)
    .test(incorrectMonthMessage, incorrectMonthMessage, expirationMonth => {
        if (!expirationMonth) return false;

        const numberExpirationMonth = Number(expirationMonth);

        return !(numberExpirationMonth < 1 || numberExpirationMonth > 12);
    });
