import { ownTemplatesEffects } from 'stores/ownTemplates';
import { draftsEffects } from 'stores/drafts';

import { designStores } from './index';

// * effects
designStores.coverStyles
    .on(ownTemplatesEffects.getTemplate.doneData, (_, { templateConfig: { coreConfig } }) => coreConfig)
    .on(
        draftsEffects.getDraft.doneData,
        (
            _,
            {
                draft: {
                    templateConfig: { coreConfig }
                }
            }
        ) => coreConfig
    );
