import { DraggableTypesEnum } from 'constants/builder/enums';

import type { QuestionnaireItems } from '../types';

export const deleteBlockVariationsHelper = (questions: QuestionnaireItems, blockId: string) => {
    const itemForDelete = questions.find(
        item => item.blockId === blockId && item.questionType === DraggableTypesEnum.Block
    );

    return questions
        .filter(item => item.id !== itemForDelete?.id)
        .map(question => {
            if (question.blockId === blockId) {
                return {
                    ...question,
                    isShown: true
                };
            }

            return question;
        });
};
