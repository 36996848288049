import type { ReactFCC } from 'types/react';

import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { KiaraLogo } from 'ui-kit/components/links/KiaraLogo';
import { logoutEvent, $user } from 'stores/user/model';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import {
    StyledNavLink,
    LogoContainer,
    LogoutButton,
    StyledAside,
    AvatarInfo,
    LogoutText,
    UserInfo,
    Content,
    Avatar
} from './styles';
import logoutIconSource from './static_assets/logout_icon.svg';
import { getActiveClassName, createLinks } from './utils';

export const Aside: ReactFCC = () => {
    const { t } = useTranslation();
    const { user } = useUnit($user);
    const links = useMemo(() => createLinks(t), [t]);

    const { firstName, lastName } = user || {};

    const firstNameFirstLetter = firstName?.slice(0, 1) || 'N';
    const lastNameFirstLetter = lastName?.slice(0, 1) || 'S';

    const handleLogout = () => {
        logoutEvent();
    };

    return (
        <StyledAside>
            <LogoContainer>
                <KiaraLogo />
            </LogoContainer>

            <Content>
                <Column>
                    {links.map(({ disabled, href, text, icon }, index) => (
                        <StyledNavLink
                            className={getActiveClassName}
                            key={`link-${index}`}
                            disabled={disabled}
                            to={href}
                        >
                            {icon}
                            {text}
                        </StyledNavLink>
                    ))}
                </Column>
            </Content>

            <UserInfo>
                <Avatar>
                    <AvatarInfo>{`${firstNameFirstLetter}${lastNameFirstLetter}`}</AvatarInfo>
                </Avatar>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    {firstName} {lastName}
                </div>
            </UserInfo>
            <ContentWrapper padding="12px 24px">
                <LogoutButton onClick={handleLogout}>
                    <MarginWrapper marginRight="10px">
                        <CustomImage src={logoutIconSource} height="20px" width="20px" />
                    </MarginWrapper>
                    <LogoutText>{t('logOut')}</LogoutText>
                </LogoutButton>
            </ContentWrapper>
        </StyledAside>
    );
};
