import type { ReactFCC } from 'types/react';

import { HOME_LINK } from 'constants/routes';
import { Navigate } from 'react-router-dom';
import { $user } from 'stores/user/model';
import { useUnit } from 'effector-react';

export const PrivateRoute: ReactFCC = ({ children }) => {
    const user = useUnit($user);

    if (user?.accessToken) return <>{children}</>;
    else return <Navigate to={HOME_LINK} />;
};
