import type { ReactFCC } from 'types/react';
import type { EditorState } from 'draft-js';

import { isStyleApplied } from '../../helpers/is-style-applied';
import { UnderlineIcon } from './static_assets';
import { Button } from './styles';

interface Props {
    editorState: EditorState;
    onClick: () => void;
}

export const UnderlineButton: ReactFCC<Props> = ({ editorState, onClick }) => (
    <Button $isActive={isStyleApplied('UNDERLINE', editorState)} onClick={onClick}>
        <UnderlineIcon />
    </Button>
);
