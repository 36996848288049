import { Button } from 'ui-kit/components/buttons/Button';
import { type ReactFCC } from 'types/react';

import { Container } from './styles';

interface Props {
    onTabChange: (tab: string) => void;
    isAddingClauseVariations: boolean;
    isAddingBlockVariations: boolean;
    activeTab: string;
}

export const Footer: ReactFCC<Props> = ({
    isAddingClauseVariations,
    isAddingBlockVariations,
    onTabChange,
    activeTab
}) => (
    <Container>
        {activeTab === 'logic' && (
            <Button
                onClick={() => {
                    onTabChange('edit');
                }}
                variant="secondary"
                type="button"
            >
                Back
            </Button>
        )}
        {(isAddingBlockVariations || isAddingClauseVariations) && activeTab !== 'logic' ? (
            <Button
                onClick={e => {
                    e.preventDefault();
                    onTabChange('logic');
                }}
                variant="primary"
                type="button"
            >
                Next
            </Button>
        ) : (
            <Button variant="primary" type="submit">
                Save
            </Button>
        )}
    </Container>
);
