import type { JoinUserData } from 'pages/JoinToCompany/types';

import { createNotifyingErrorEffect } from 'utils/store';
import { createEvent, restore } from 'effector';
import queryString from 'qs';
import { API } from 'api';

import type { GetCompanyUsersProps } from './types';

// * events
const inviteUserEvent = createEvent<{ role: 'company-admin' | 'employees'; companyId: string; email: string }>();
const resetCompany = createEvent();

// * effects
const getCompany = createNotifyingErrorEffect({
    handler: async (id: string) => await API.company.getCompany(id)
});

const inviteUser = createNotifyingErrorEffect({
    handler: async (data: API.InviteDto) => await API.company.inviteUser(data)
});

const addCompany = createNotifyingErrorEffect({
    handler: async (data: API.RegisterCompanyDto) => await API.company.addCompany(data)
});

const joinUser = createNotifyingErrorEffect({
    handler: async ({ companyId, data }: { data: JoinUserData; companyId: string }) =>
        await API.company.joinUser(data, companyId)
});

const getCompanyTemplates = createNotifyingErrorEffect({
    handler: async (data: { limit: number; page: number }) => {
        const { limit, page } = data;
        const newQuery = queryString.stringify({
            limit,
            page
        });

        return await API.templates.getCompanyTemplates(newQuery);
    }
});

const createCompanyTemplate = createNotifyingErrorEffect({
    handler: async (data: API.CreateCompanyTemplateDto) => await API.templates.createCompanyTemplate(data)
});

const deleteCompanyTemplate = createNotifyingErrorEffect({
    handler: async (id: string) => await API.templates.deleteCompanyTemplate(id)
});

const getCompanyUsers = createNotifyingErrorEffect({
    handler: async ({ companyId, roleId, search, limit, page }: GetCompanyUsersProps) => {
        const newQuery = queryString.stringify({
            companyId,
            roleId,
            search,
            limit,
            page
        });

        return await API.company.getCompanyUsers(newQuery);
    }
});

const deleteCompanyUser = createNotifyingErrorEffect({
    handler: async ({ userId }: { userId: string }) => await API.company.deleteCompanyUser(userId)
});

const editCompanyTemplate = createNotifyingErrorEffect({
    handler: async ({ data, id }: { data: API.UpdateCompanyTemplateDto; id: string }) =>
        await API.templates.editCompanyTemplate(id, data)
});

const updateCompanyInfo = createNotifyingErrorEffect({
    handler: async ({ companyId, data }: { data: API.UpdateCompanyDto; companyId: string }) =>
        await API.company.updateCompanyInfo(data, companyId)
});

// * stores
const company = restore<API.Company>(getCompany.doneData, null).reset(resetCompany);
// TODO: update any when API.GetManyResponseForUser is updated
const companyUsers = restore<any>(getCompanyUsers.doneData, null);
const companyTemplates = restore<API.GetManyResponseForCompanyTemplates>(getCompanyTemplates.doneData, null);

// * exports
export const companyEvents = {
    inviteUserEvent,
    resetCompany
};

export const companyStores = {
    companyTemplates,
    companyUsers,
    company
};

export const companyEffects = {
    deleteCompanyTemplate,
    createCompanyTemplate,
    getCompanyTemplates,
    editCompanyTemplate,
    deleteCompanyUser,
    updateCompanyInfo,
    getCompanyUsers,
    getCompany,
    inviteUser,
    addCompany,
    joinUser
};
