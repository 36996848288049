import { PURPLE_100, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;

export const Container = styled.div`
    min-height: 370px;
`;

export const Text = styled.span`
    font-weight: 400;
    font-size: 14px;
    color: ${PURPLE_100};
    margin-bottom: 16px;
`;

export const Content = styled.div``;

export const Pallet = styled.div`
    width: 100%;
    height: 48px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    margin-bottom: 20px;
`;

export const First = styled.div`
    width: 184px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
`;

export const Second = styled.div`
    width: 92px;
    height: 100%;
`;

export const Third = styled.div`
    width: 46px;
    height: 100%;
`;

export const Fourth = styled.div`
    width: 48px;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
`;

export const ColorPickerContainer = styled.div`
    padding: 12px;
    background-color: ${WHITE};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 100%;
`;

export const InputContainer = styled.div`
    position: relative;
    margin-top: 16px;
`;

export const Input = styled.input`
    border: 1px solid #d0d3d9;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 14px;
    color: ${PURPLE_100};
    width: 100%;

    &:focus {
        outline: none;
    }
`;

export const ColorPickerColor = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    right: 10px;
    top: 10px;
`;

export const Span = styled.span``;
