import type { Fields } from 'types/builder/interface';

import { v4 as generateId } from 'uuid';

import type { BlockState } from '../types';

export const addSigningBlockRowReducer = (
    state: BlockState,
    {
        component,
        blockId,
        clause
    }: {
        component: string;
        blockId: string;
        clause: Fields;
    }
) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups:
                      component === 'UPFRONT SUMMARY'
                          ? [
                                ...block.groups,
                                {
                                    fields: [
                                        {
                                            ...clause
                                        },
                                        {
                                            id: generateId(),
                                            type: 'EMPTY'
                                        },
                                        {
                                            id: generateId(),
                                            type: 'EMPTY'
                                        }
                                    ],
                                    id: generateId()
                                }
                            ].filter(group => !group.fields.every(field => field.type === 'EMPTY'))
                          : [
                                ...block.groups,
                                {
                                    fields: [
                                        {
                                            ...clause
                                        },
                                        {
                                            id: generateId(),
                                            type: 'EMPTY'
                                        }
                                    ],
                                    id: generateId()
                                }
                            ].filter(group => !group.fields.every(field => field.type === 'EMPTY'))
              }
            : block
    )
});
