import { companyTemplateNamingModalStore, deleteCompanyUserModalStore, areYouSureModalStore } from 'stores/modals';
import { checkEmailForExistenceFx, logoutEvent } from 'stores/user/model';
import { roleStores } from 'stores/role';
import { sample } from 'effector';

import { companyEffects, companyEvents, companyStores } from './index';
import { paginationLimit } from './constants';

const { company } = companyStores;
const {
    deleteCompanyTemplate,
    createCompanyTemplate,
    getCompanyTemplates,
    deleteCompanyUser,
    getCompanyUsers,
    inviteUser
} = companyEffects;
const { inviteUserEvent, resetCompany } = companyEvents;
const { searchRoleId } = roleStores;

createCompanyTemplate.doneData.watch(() => {
    companyTemplateNamingModalStore.closeModal();
});
deleteCompanyUser.doneData.watch(() => {
    deleteCompanyUserModalStore.closeModal();
});
logoutEvent.watch(() => {
    resetCompany();
});

sample({
    fn: async () => {
        await getCompanyTemplates({ limit: 10, page: 1 });
        areYouSureModalStore.closeModal();
    },
    clock: deleteCompanyTemplate.doneData
});

sample({
    target: checkEmailForExistenceFx,
    fn: ({ email }) => ({ email }),
    clock: inviteUserEvent
});

sample({
    fn: inviteUserPayload => inviteUserPayload,
    clock: checkEmailForExistenceFx.doneData,
    source: inviteUserEvent,
    target: inviteUser
});

sample({
    fn: ({ searchRoleId, company }) => ({
        limit: paginationLimit,
        companyId: company?.id,
        roleId: searchRoleId,
        page: 1
    }),
    clock: deleteCompanyUser.doneData,
    source: { searchRoleId, company },
    target: getCompanyUsers
});
