import { DatePicker } from 'ui-kit/components/DatePicker';
import { GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import { Modal } from 'ui-kit/components/Modal';
import { type ReactFCC } from 'types/react';

import { FooterButtonWrap, DateInputWrap, TextInputWrap, PlusIconWrap, SaveButton, Subtitle } from './styles';
import { StyledTextInput } from '../../common/inputs/StyledInput';
import { createReminderModalStore } from '../../../stores/modals';
import { reFormatFromStringToDate } from '../../../utils/date';
import { PlusIconReminder } from './static_assets';

export const CreateReminderModal: ReactFCC = () => {
    const { closeModal } = createReminderModalStore;

    return (
        <Modal title="Create reminder" onClose={closeModal} width="530px" visible>
            <TextInputWrap>
                <StyledTextInput placeholder="Enter reminder title here..." title="Title" />
                <StyledTextInput placeholder="Enter reminder description here..." title="Description" />
            </TextInputWrap>
            <DateInputWrap>
                <DatePicker
                    defaultDate={reFormatFromStringToDate(new Date().toDateString())}
                    titleColor={GRAY_100}
                    title="Select date"
                />
                <StyledTextInput placeholder="Enter time" title="Time" />
            </DateInputWrap>
            <Subtitle>Members</Subtitle>
            <PlusIconWrap>
                <PlusIconReminder />
            </PlusIconWrap>
            <FooterButtonWrap>
                <SaveButton onClick={() => closeModal()}>Save</SaveButton>
            </FooterButtonWrap>
        </Modal>
    );
};
