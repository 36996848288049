import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import { PURPLE_60 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
    ${disableDefaultHStyleMixin};
    font-size: 16px;
    max-height: 485px;
    ${({ color }) => color && `color: ${color}`};

    & a {
        color: ${PURPLE_60};

        :hover {
            text-decoration: underline;
        }
    }
`;
