import type { ReactFCC } from 'types/react';

import { useNavigate } from 'react-router-dom';
import { KiaraLogoIcon } from 'ui-kit/assets';
import { HOME_LINK } from 'constants/routes';

import { StyledLogoImg } from './styles';

interface Props {
    disabled?: boolean;
    color?: string;
}

export const KiaraLogo: ReactFCC<Props> = ({ disabled, color }) => {
    const navigate = useNavigate();

    const onGoHome = () => navigate(HOME_LINK);

    return (
        <StyledLogoImg $disabled={disabled} onClick={onGoHome} $color={color} role="button">
            <KiaraLogoIcon />
        </StyledLogoImg>
    );
};
