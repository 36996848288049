import { yupEmail } from 'constants/yupFields';
import * as Yup from 'yup';

export const yupSchema = Yup.object().shape({
    email: yupEmail
});

// export const SERVICE_ID = 'service_qmu2n3g';
// export const TEMPLATE_ID = 'template_rqinpck';
// export const PUBLIC_KEY = '8YwmAnAqnfMovfQDd';

export const SERVICE_ID = 'service_3b5shlg';
export const TEMPLATE_ID = 'template_mq8b1rw';
export const PUBLIC_KEY = 'bhz2NkxwXp2TE3zGV';
