import { Flex, Row } from 'components/wrappers/grid/FlexWrapper';
import { KiaraLogo } from 'ui-kit/components/links/KiaraLogo';
import { PRIVACY_POLICY_LINK } from 'constants/routes';
import { ORANGE } from 'ui-kit/GlobalStyle/colors';
import { cookiesModalStore } from 'stores/modals';
import { Switch } from 'ui-kit/components/Switch';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { type ReactFCC } from 'types/react';
import { useState } from 'react';

import {
    HighlightedText,
    SwitchContainer,
    ParagraphTitle,
    CustomButton,
    SwitchTitle,
    Container,
    CookieImg,
    ListTitle,
    PageTitle,
    Paragraph,
    Buttons,
    UL,
    LI,
    LP
} from './styles';
import cookie from './static_assets/cookie.svg';
import { switchValues } from './constants';

export const CookiesModal: ReactFCC = () => {
    const { closeModal } = cookiesModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [firstSwitchValue, setFirstSwitchValue] = useState(false);
    const [secondSwitchValue, setSecondSwitchValue] = useState(false);

    const goToPrivacyPolicy = () => {
        closeModal();
        navigate(PRIVACY_POLICY_LINK);
    };

    const handleFirstSwitchChange = () => {
        setFirstSwitchValue(!firstSwitchValue);
    };

    const handleSecondSwitchChange = () => {
        setSecondSwitchValue(!secondSwitchValue);
    };

    const acceptAll = () => {
        closeModal();
    };

    const acceptSelection = () => {
        closeModal();
    };

    const handleClose = () => {
        closeModal();
    };

    return (
        <Modal
            customFooter={
                <Buttons>
                    <CustomButton backgroundColor={ORANGE} onClick={acceptAll}>
                        {t('cookiesModal.button1')}
                    </CustomButton>
                    <CustomButton onClick={acceptSelection}>{t('cookiesModal.button2')}</CustomButton>
                </Buttons>
            }
            onClose={handleClose}
            visible
        >
            <Container>
                <Flex justifyBetween alignCenter>
                    <Flex alignCenter>
                        <PageTitle>{t('cookiesModal.pageTitle')}</PageTitle>
                        <CookieImg alt="cookie_icon" src={cookie} />
                    </Flex>
                    <KiaraLogo />
                </Flex>
                <ListTitle>{t('cookiesModal.list1.title')}</ListTitle>
                <UL>
                    <LI>
                        <LP>{t('cookiesModal.list1.row1')}</LP>
                    </LI>
                    <LI>
                        <LP>{t('cookiesModal.list1.row2')}</LP>
                    </LI>
                    <LI>
                        <LP>{t('cookiesModal.list1.row3')}</LP>
                    </LI>
                    <LI>
                        <LP>{t('cookiesModal.list1.row4')}</LP>
                    </LI>
                    <LI>
                        <LP>{t('cookiesModal.list1.row5')}</LP>
                    </LI>
                </UL>

                <ParagraphTitle>{t('cookiesModal.paragraphTitle1')}</ParagraphTitle>
                <Row>
                    <Paragraph>{t('cookiesModal.paragraph1')}</Paragraph>
                    <SwitchContainer>
                        <SwitchTitle>{switchValues[+firstSwitchValue]}</SwitchTitle>
                        <Switch onChange={handleFirstSwitchChange} />
                    </SwitchContainer>
                </Row>

                <ParagraphTitle>{t('cookiesModal.paragraphTitle2')}</ParagraphTitle>
                <Row>
                    <Paragraph>{t('cookiesModal.paragraph2')}</Paragraph>
                    <SwitchContainer>
                        <SwitchTitle>{switchValues[+secondSwitchValue]}</SwitchTitle>
                        <Switch onChange={handleSecondSwitchChange} />
                    </SwitchContainer>
                </Row>

                <ParagraphTitle>{t('cookiesModal.paragraphTitle3')}</ParagraphTitle>
                <Paragraph>
                    {t('cookiesModal.paragraph3.text1')}{' '}
                    <HighlightedText onClick={goToPrivacyPolicy}>
                        {t('cookiesModal.paragraph3.highlight')}
                    </HighlightedText>
                </Paragraph>
                {t('cookiesModal.paragraph3.text2')}
            </Container>
        </Modal>
    );
};
