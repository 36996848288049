export const serializeForm = (form: HTMLFormElement) => {
    const obj = {} as any;
    const formData = new FormData(form);

    for (const key of formData.keys()) {
        obj[key] = formData.get(key);
    }

    return obj;
};
