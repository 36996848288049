export const customStyleMap = {
    CODE: {
        background: 'rgb(241, 241, 241)',
        fontFamily: 'monospace',
        borderRadius: '3px',
        padding: '1px 3px'
    },
    SUPERSCRIPT: {
        verticalAlign: 'super',
        fontSize: '12px'
    },
    SUBSCRIPT: {
        verticalAlign: 'sub',
        fontSize: '12px'
    }
};
