import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { BrowserRouter } from 'routes/BrowserRouter';
import { browserHistory } from 'browserHistory';
import ReactDOM from 'react-dom/client';
import 'ui-kit/assets/fonts/index.scss';

import * as serviceWorker from './serviceWorker';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter history={browserHistory}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>
);

serviceWorker.unregister();
