import type { ClauseInterface } from 'types/builder/interface';

import { createEvent, createStore } from 'effector';

import type { ChangeClauseTitleEventProps, DeleteClauseEventProps, AddClauseEventProps } from './types';

// * events
const addClauseEvent = createEvent<AddClauseEventProps>();
const deleteClauseEvent = createEvent<DeleteClauseEventProps>();
const changeClauseTitleEvent = createEvent<ChangeClauseTitleEventProps>();
const toggleNumberingEvent = createEvent();
const addVariant = createEvent<{
    clauseId: string;
    blockId: string;
}>();
const deleteVariant = createEvent<{
    contentId: string;
    clauseId: string;
    blockId: string;
}>();
const makeIsDefaultEvent = createEvent<{
    contentId: string;
    clauseId: string;
    blockId: string;
}>();

// * stores
const clauses = createStore<ClauseInterface[]>([]);

// * exports
export const clauseEvents = {
    changeClauseTitleEvent,
    toggleNumberingEvent,
    makeIsDefaultEvent,
    deleteClauseEvent,
    addClauseEvent,
    deleteVariant,
    addVariant
};

export const clauseStores = {
    clauses
};
