import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { Button } from 'ui-kit/components/buttons/Button';
import styled from 'styled-components';
import media from 'utils/media-query';

export const Page404 = styled(Column)`
    width: 742px;
    margin-left: auto;
    margin-right: auto;

    ${media.desktopLg`
        width: 632px;
    `}

    ${media.desktop`
        width: 588px;
    `}

    ${media.tablet`
        width: 410px;
    `}

    ${media.phone`
        width: 318px;
    `}
`;

export const Image = styled(CustomImage)`
    width: 100%;
    height: auto;
`;

export const Description = styled.p`
    ${disableDefaultHStyleMixin};
    letter-spacing: 0;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 30px;
    margin-top: 62px;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;

    ${media.desktopLg`
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 25px;
        margin-top: 58px;
        padding-left: 24px;
        padding-right: 24px;
    `}

    ${media.desktop`
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 30px;
        margin-top: 60px;
        padding-left: 60px;
        padding-right: 60px;
    `}

    ${media.tablet`
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
        margin-top: 36px;
        padding-left: 30px;
        padding-right: 30px;
    `}

    ${media.phone`
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
        margin-top: 24px;
        padding-left: 46px;
        padding-right: 46px;
    `}
`;

export const BackToHome = styled(Button)`
    width: 255px;
    height: 64px;
    font-size: 24px;
    line-height: 30px;

    ${media.desktopLg`
        width: 184px;
        height: 48px;
        font-size: 18px;
        line-height: 24px;
    `}

    ${media.desktop`
        width: 165px;
        height: 42px;
        font-size: 16px;
        line-height: 20px;
    `}

    ${media.tablet`
        width: 122px;
        height: 28px;
        font-size: 12px;
        line-height: 14px;
    `}

    ${media.phone`
        width: 100%;
        height: 56px;
        font-size: 16px;
        line-height: 20px;
    `}
`;
