import styled from 'styled-components';

export const Container = styled.div`
    width: 700px;
`;

export const Form = styled.form``;

export const Content = styled.div`
    overflow: auto;
    width: 100%;
    max-height: 485px;
    padding-right: 8px;
`;

export const EditorContainer = styled.div`
    padding-bottom: 16px;
`;
