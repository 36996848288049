import { PURPLE_40 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    background-color: ${PURPLE_40};
`;

export const Wrapper = styled.div`
    display: flex;
`;
