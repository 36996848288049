import styled, { css } from 'styled-components';

interface Props {
    fullScreen?: boolean;
}

const fullscreenCircleMixin = css`
    border: 6px solid #0014cb;
    border-top: 6px solid #0014cb50;
    width: 60px;
    height: 60px;
`;
const buttonCircleMixin = css`
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-top: 3px solid rgba(255, 255, 255, 1);
    width: 18px;
    height: 18px;
`;

export const PreloaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
`;

export const PreloaderCircle = styled.div<Props>`
    ${({ fullScreen }) => (fullScreen ? fullscreenCircleMixin : buttonCircleMixin)};
    border-radius: 50%;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
