import type { Sizes } from 'types/styles';

import { WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

interface Props extends Sizes {}

export const AppLayout = styled.div<Props>`
    position: relative;
    width: 100%;
    ${({ height }) => height && `height: ${height}`};
    background-color: ${WHITE};
`;
