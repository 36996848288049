import type { ReactFCC } from 'types/react';

import { Aside } from 'components/Aside';

import { Container, Wrapper } from './styles';

export const DashboardLayout: ReactFCC = ({ children }) => (
    <Wrapper>
        <Aside />
        <Container>{children}</Container>
    </Wrapper>
);
