import styled from 'styled-components';

export const Container = styled.div`
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--white);
    border-bottom: 1px solid #d0d3d9;
    padding: 8px 12px;
    margin-bottom: 0;
    display: flex;
    gap: 8px;
`;
