import type { ImgWrapperProps, ImgProps } from 'components/common/imgComponents/CustomImg/types';

import { flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

interface CustomImageProps extends ImgProps {
    disabled?: boolean;
    diameter?: string;
}

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    background-size: auto;
`;

export const CustomImage = styled.img<CustomImageProps>`
    width: ${({ diameter, width }) => diameter || width || '100%'};
    height: ${({ diameter, height }) => diameter || height || '100%'};
    ${flexCenter};
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}; overflow: hidden`};
    overflow: hidden;
    object-fit: ${({ objectFit }) => objectFit || 'cover'};
    ${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`};
    ${({ pointer }) => pointer && 'cursor: pointer;'};
    ${({ center }) => center && 'margin: auto;'};
    ${({ zIndex }) => zIndex !== undefined && `z-index: ${zIndex};`};
    user-select: none;
    flex-shrink: 0;

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const ImgWrapper = styled.div<ImgWrapperProps>`
    ${flexCenter};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    ${({ center }) => center && 'margin: auto;'};
`;
