import type { TextProperties, Color } from 'types/styles';

import { PURPLE_100, PURPLE_60, GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';
import media from 'utils/media-query';

export interface StyledLinkProps extends Color, TextProperties {}

export const StyledLink = styled.a<StyledLinkProps>`
    font-size: ${({ fontSize }) =>
        fontSize ||
        css`
            font-size: 24px;

            ${media.desktopLg`
                font-size: 18px;
            `}

            ${media.desktop`
                font-size: 16px;
            `}

            ${media.tablet`
                font-size: 12px;
            `}
        `};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    color: ${({ color }) => color || PURPLE_60};
    &:hover {
        color: ${({ color }) => color || GRAY_100};
    }
    &:active {
        color: ${({ color }) => color || PURPLE_100};
    }
`;
