import { PURPLE_60, GRAY_100, GRAY_20, GRAY_80, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

interface Props {
    disabled?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 8px;
`;

export const Content = styled.div<Props>`
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
        width: max-content;
    }

    & input {
        width: 250px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;

            & input {
                background-color: ${GRAY_20};
                color: ${GRAY_80};
            }
        `}
`;

export const Title = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: ${GRAY_100};
    margin-bottom: -4px;
`;

export const Button = styled.button`
    background-color: ${GRAY_20};
    border: none;
    padding: 0;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${PURPLE_60};
    cursor: pointer;

    &:hover {
        background-color: ${PURPLE_60};
        color: ${WHITE};
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
