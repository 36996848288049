import axios from 'api/axios';

export const createDocument = (data: KIARA.DocumentRequest) =>
    axios({
        url: 'documents',
        method: 'POST',
        data
    });

export const getDocuments = () =>
    axios<KIARA.DocumentResponse[]>({
        url: 'documents',
        method: 'GET'
    });

export const getSingleDocument = (id: string) =>
    axios({
        url: `documents/${id}`,
        method: 'GET'
    });

export const deleteDocument = (id: string) =>
    axios({
        url: `documents/${id}`,
        method: 'DELETE'
    });
