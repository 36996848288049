import type { SimpleModalStore } from 'stores/modals/types';

import { createEffect, type Effect, createEvent, createStore, type Store, combine } from 'effector';
import { message } from 'stores/alerts';

interface ErrorWithData {
    data?: {
        message: string;
    };
}

export const createNotifyingErrorEffect = <Params, Done>(config: {
    handler?: (params: Params) => Promise<Done> | Done;
    name?: string;
    sid?: string;
}): Effect<Params, Done> => {
    const effect = createEffect(config);

    effect.fail.watch(({ error }) => {
        const errorWithData = error as ErrorWithData | undefined;

        message.error(errorWithData?.data?.message || 'Uncaught Error, please contact with customer care!');
    });

    return effect;
};

export const createNotifyingSuccessEffect = <Params, Done extends API.SuccessAnswer>(config: {
    handler?: (params: Params) => Promise<Done>;
    name?: string;
    sid?: string;
}): Effect<Params, Done> => {
    const effect = createEffect(config);

    effect.fail.watch(({ error }) => {
        const errorWithData = error as ErrorWithData | undefined;

        message.error(errorWithData?.data?.message || 'Uncaught Error, please contact with customer care!');
    });
    effect.doneData.watch(({ message: messageText }) => {
        message.success(messageText);
    });

    return effect;
};

const combinePendingStores = (effects: Array<Effect<any, any>>) => combine(effects.map(effect => effect.pending));

export const createIsLoadingStore = (effects: Array<Effect<any, any>>): Store<boolean> =>
    combinePendingStores(effects).map(pendingStatuses => pendingStatuses.some(pending => pending));

export const initializeStoreModal = () => {
    const openModal = createEvent();
    const closeModal = createEvent();

    const modal = createStore<SimpleModalStore>({
        visible: false
    })
        .on(openModal, () => ({
            visible: true
        }))
        .on(closeModal, () => ({
            visible: false
        }));

    return { closeModal, openModal, modal };
};

export const initializeGenericStore = <T>(initialState: T) => {
    const openModal = createEvent<T>();
    const closeModal = createEvent();

    const visible = createStore(false)
        .on(openModal, () => true)
        .on(closeModal, () => false);
    const state = createStore<T>(initialState).on(openModal, (_, newState) => newState);
    const modal = combine(visible, state);

    return { closeModal, openModal, modal };
};
