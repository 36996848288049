import { RadioButton, ListBullets, Calendar, Article, Checks } from 'phosphor-react';
import { FieldTypesEnum } from 'constants/builder/enums';

export const inputTypesForField = [
    { value: FieldTypesEnum.Text, label: FieldTypesEnum.Text, icon: <Article /> },
    { value: FieldTypesEnum.Date, label: FieldTypesEnum.Date, icon: <Calendar /> },
    { value: FieldTypesEnum.MultiSelect, label: FieldTypesEnum.MultiSelect, icon: <Checks /> },
    { value: FieldTypesEnum.Select, label: FieldTypesEnum.Select, icon: <ListBullets /> },
    { value: FieldTypesEnum.Radio, label: FieldTypesEnum.Radio, icon: <RadioButton /> }
];

export const inputTypesForClause = [
    { value: FieldTypesEnum.Radio, label: FieldTypesEnum.Radio, icon: <RadioButton /> },
    { value: FieldTypesEnum.Select, label: FieldTypesEnum.Select, icon: <ListBullets /> }
];

export const icons = {
    MultiSelect: <Checks />,
    Select: <ListBullets />,
    Radio: <RadioButton />,
    Date: <Calendar />,
    Text: <Article />
};
