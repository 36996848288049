import { THANK_YOU_REGISTRATION_LINK, DOCUMENTS_LINK, PASSWORD_RESET, AUTH_LINK, HOME_LINK } from 'constants/routes';
import { companyEffects, companyStores } from 'stores/company';
import { companyUserChangeModalStore } from 'stores/modals';
import { browserHistory } from 'browserHistory';
import { roleStores } from 'stores/role';
import { message } from 'stores/alerts';
import { sample } from 'effector';

import {
    checkEmailForExistenceFx,
    updateUserPasswordFx,
    recoverPasswordFx,
    changePasswordFx,
    changeUserRoleFx,
    verifyAccountFx,
    createUserFx,
    loadTokenFx,
    logoutEvent
} from './model';

const { company } = companyStores;
const { getCompanyUsers } = companyEffects;
const { searchRoleId } = roleStores;

updateUserPasswordFx.doneData.watch(() => {
    logoutEvent();
    browserHistory.push(AUTH_LINK);
});

createUserFx.doneData.watch(() => {
    browserHistory.push(THANK_YOU_REGISTRATION_LINK);
});

loadTokenFx.doneData.watch(() => {
    browserHistory.push(DOCUMENTS_LINK);
});

checkEmailForExistenceFx.fail.watch(() => {
    message.error('Email already exists');
});

changeUserRoleFx.doneData.watch(() => {
    companyUserChangeModalStore.closeModal();
});

recoverPasswordFx.doneData.watch(() => {
    browserHistory.push(PASSWORD_RESET);
});

changePasswordFx.doneData.watch(() => {
    browserHistory.push(AUTH_LINK);
});

verifyAccountFx.doneData.watch(() => {
    browserHistory.push(DOCUMENTS_LINK);
});

verifyAccountFx.failData.watch(() => {
    browserHistory.push(HOME_LINK);
});

sample({
    fn: ({ searchRoleId, company }) => ({
        companyId: company?.id,
        roleId: searchRoleId,
        limit: 5,
        page: 1
    }),
    source: { searchRoleId, company },
    clock: changeUserRoleFx.doneData,
    target: getCompanyUsers
});
