import type { ReactFCC } from 'types/react';

import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { updateSubscriptionModalStore } from 'stores/modals';
import { Button } from 'ui-kit/components/buttons/Button';
import { GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { PRICES_LINK } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

export const UpdateSubscriptionModal: ReactFCC = () => {
    const { closeModal, modal } = updateSubscriptionModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [_, { isFromConstructor, price }] = useUnit(modal);

    const updateSubscription = () => {
        closeModal();
        navigate(`${PRICES_LINK}?isFromConstructor=${isFromConstructor}`);
    };

    const buyContract = () => {
        updateSubscriptionModalStore.closeModal();
    };

    return (
        <Modal
            title={t('updateSubscriptionModal.updateSubscription') as string}
            onClose={closeModal}
            width="555px"
            visible
        >
            {/* TODO: improve */}
            <span>{t('updateSubscriptionModal.subtitle')}</span>

            <MarginWrapper marginTop="30px" />
            <AdaptiveGrid alignItems="center" xxl="1fr auto auto">
                <Button onClick={updateSubscription} variant="primary">
                    {t('updateSubscriptionModal.primaryButton')}
                </Button>

                <P color={GRAY_100}>or</P>

                <Button onClick={buyContract} variant="secondary">
                    {t('updateSubscriptionModal.secondaryButton')} {price}&#8364;
                </Button>
            </AdaptiveGrid>
        </Modal>
    );
};
