import { createEvent } from 'effector';

// * events
const addTimeLineEvent = createEvent<{
    // TODO: any
    block: any;
}>();
const addSectionToRowEvent = createEvent<{
    blockId: string;
    // TODO: any
    clause: any;
}>();
const reorderFieldsInGroup = createEvent<{
    groupId: string;
    // TODO: any
    items: any[];
}>();
const deleteSectionEvent = createEvent<{
    blockId: string;
    fieldId: string;
}>();

// * exports
export const timeLineEvents = {
    addSectionToRowEvent,
    reorderFieldsInGroup,
    deleteSectionEvent,
    addTimeLineEvent
};
