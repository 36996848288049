import axios from 'api/axios';

export const createCompanyTemplate = (data: API.CreateCompanyTemplateDto) =>
    axios<API.CompanyTemplate>({
        url: 'company-templates',
        data
    });

export const getCompanyTemplates = (query: string) =>
    axios<API.GetManyResponseForCompanyTemplates>({
        url: `company-templates/my?${query}`,
        method: 'GET'
    });

export const deleteCompanyTemplate = (id: string) =>
    axios({
        url: `company-templates/${id}`,
        method: 'DELETE'
    });

export const editCompanyTemplate = (id: string, data: API.UpdateCompanyTemplateDto) =>
    axios({
        url: `company-templates/${id}`,
        method: 'PUT',
        data
    });
