import type { QuestionnaireValues } from 'types/questionnare';

import type { QuestionnaireState } from '../types';

import { changeClauseVariationsHelper } from '../helpers/change-clause-variations-helper';
import { changeBlockVariationsHelper } from '../helpers/change-block-variations-helper';
import { changeFieldsHelper } from '../helpers/change-fields-helper';

export const addQuestionnaireValuesReducer = (
    state: QuestionnaireState,
    { isBlockVariation, isFieldVariation, contentIds, clauseId, fieldId, blockId, answer, value }: QuestionnaireValues
) => {
    const newState = {
        ...state,
        questions: state.questions.map(question => {
            if (isBlockVariation) {
                const modifiedQuestion = changeBlockVariationsHelper({
                    questions: state.questions,
                    question,
                    blockId,
                    fieldId,
                    answer,
                    value
                });

                if (modifiedQuestion) {
                    return modifiedQuestion;
                }
            }

            if (isFieldVariation) {
                const modifiedQuestion = changeClauseVariationsHelper({
                    contentIds,
                    clauseId,
                    question,
                    fieldId,
                    answer,
                    value
                });

                if (modifiedQuestion) {
                    return modifiedQuestion;
                }
            }

            if (question.fieldId === fieldId && !isFieldVariation && !isBlockVariation) {
                return changeFieldsHelper(question, value);
            }

            return question;
        })
    };

    return newState;
};
