import {
    wrapperHorizontalPadding,
    wrapperVerticalPadding,
    wrapperBorderRadius,
    transitionDuration,
    wrapperHeight,
    translateY
} from 'components/modals/Notification/constants';
import { primaryShadowMixin, flexCenter } from 'constants/styles/mixins';
import { DANGER, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

interface Props {
    active?: boolean;
}

export const Wrapper = styled.div<Props>`
    position: fixed;
    top: -72px;
    right: 35px;
    transition-duration: 0.5s;
    transition-property: transform, box-shadow, opacity;
    z-index: 1001;

    ${({ active }) =>
        active &&
        `
            transform: translateY(${translateY});
        `}
`;

export const StyledNotification = styled.div<Props>`
    ${flexCenter};
    flex-wrap: nowrap;
    flex-direction: row;
    height: ${wrapperHeight};
    border-radius: ${wrapperBorderRadius};
    background-color: ${WHITE};
    padding: ${wrapperVerticalPadding} ${wrapperHorizontalPadding};
    opacity: 0;
    transition-duration: ${transitionDuration};
    transition-property: transform, box-shadow, opacity;

    ${({ active }) => active && `${primaryShadowMixin}; opacity: 1;`};
`;

interface ContentTextProp {
    error?: boolean;
}

export const ContentText = styled.span<ContentTextProp>`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    ${({ error }) => error && `color: ${DANGER}`};
`;
