import type { ReactFCC } from 'types/react';

import { StyledNotification, ContentText, Wrapper } from 'components/modals/Notification/styles';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { successImgDiameter } from 'components/modals/Notification/constants';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { alertsStores } from 'stores/alerts';
import { useUnit } from 'effector-react';

import successImg from './static_assets/success_notification_icon.svg';
import errorImg from './static_assets/error_notification_icon.svg';

export const NotificationModal: ReactFCC = () => {
    const { message, type } = useUnit(alertsStores.alertStore);

    return (
        <Wrapper active>
            <StyledNotification active>
                {type === 'error' ? (
                    <CustomImage width={successImgDiameter} src={errorImg} height="23px" />
                ) : (
                    <CustomImage width={successImgDiameter} src={successImg} height="20px" />
                )}
                <MarginWrapper marginLeft="10px">
                    <ContentText error={type === 'error'}>{message}</ContentText>
                </MarginWrapper>
            </StyledNotification>
        </Wrapper>
    );
};
