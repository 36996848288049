import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { copyTemplatesEffects } from 'stores/copyTemplates';
import { Button } from 'ui-kit/components/buttons/Button';
import { ownTemplatesEffects } from 'stores/ownTemplates';
import { copyTemplateModalStore } from 'stores/modals';
import { Input } from 'ui-kit/components/inputs/Input';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import { Column, Row } from './styles';

export const CopyTemplateModal: ReactFCC = () => {
    const { closeModal, modal } = copyTemplateModalStore;
    const [_, { templateId, from }] = useUnit(modal);
    const [templateName, setTemplateName] = useState('');
    const navigate = useNavigate();

    const onCloseModal = useCallback(() => {
        closeModal();
        setTemplateName('');
    }, [closeModal]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    };

    const onCreateACopy = () => {
        copyTemplatesEffects.copyTemplate({
            location: 'own',
            templateName,
            templateId
        });
    };

    // TODO: refactor this
    useEffect(() => {
        copyTemplatesEffects.copyTemplate.doneData.watch(() => {
            if (from === 'kiara') {
                navigate(`/dashboard/templates?tab=Your templates`);
            } else {
                ownTemplatesEffects.getTemplates(null);
            }
            onCloseModal();
        });

        return () => {
            setTemplateName('');
        };
    }, [onCloseModal, from, navigate]);

    return (
        <Modal onClose={onCloseModal} title="Create a copy" width="530px" visible>
            <Column>
                <Input
                    placeholder="Enter template name here..."
                    title="Template name"
                    value={templateName}
                    onChange={onChange}
                />
                <Row>
                    <Button onClick={() => onCloseModal()} variant="secondary">
                        Cancel
                    </Button>
                    <Button disabled={!templateName} onClick={onCreateACopy} variant="primary">
                        Create
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
