import { hideOrShowEvents, hideOrShowStores } from 'stores/builder/hideOrShowItems';
import { setCurrentFieldsEvent, $currentFields } from 'stores/builder/field/model';
import { EditorLocationEnum, FieldTypesEnum } from 'constants/builder/enums';
import { type BlockInterface, type Fields } from 'types/builder/interface';
import { editorEvents, editorStores } from 'stores/builder/editor';
import { stateFromHTML } from 'draft-js-import-html';
import { serializeForm } from 'utils/serialize-form';
import { useEffect, useState, useRef } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { blockEvents } from 'stores/builder/block';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';
import { v4 as generateId } from 'uuid';
import { EditorState } from 'draft-js';

import { type AddOrRemoveButtonProps, VariationsEditor } from './editors/VariationsEditor';
import { SelectMultiSelectRadioEditor } from './editors/SelectMultiSelectRadioEditor';
import { type FormFieldsErrors, type VariationItems, type FieldItem } from './types';
import { getVariationItems, getVariationTitle, getSelectedValue } from './utils';
import { EditorContainer, Container, Content, Form } from './styles';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { getDefaultShowOrHideString } from './tabs/Logic/utils';
import { Edit } from './tabs/Edit';
import { Footer } from './Footer';

interface Props {
    variationsBlock?: BlockInterface['variationsBlock'];
    variationsField?: Fields['variationsField'];
    onClose: () => void;
    blockTitle?: string;
    selectedField?: any;
    clauseId?: string;
    blockId?: string;
    clause?: Fields;
    textId?: string;
}

export interface RadioItem {
    type: FieldTypesEnum;
    value: string;
    text: string;
}

export const RichComponent: ReactFCC<Props> = ({
    variationsBlock,
    variationsField,
    clauseId = '',
    selectedField,
    blockId = '',
    textId = '',
    blockTitle,
    onClose,
    clause
}) => {
    const showOrHide = useUnit(hideOrShowStores.showOrHide);
    const multiSelectValues = useUnit(hideOrShowStores.multiSelectValues);
    const location = useUnit(editorStores.location);
    const editorState = useUnit(editorStores.editorState);
    const previousCurrentFieldsState = useUnit($currentFields);

    const [activeTab, setActiveTab] = useState('edit');

    const [selectedValue, setSelectedValue] = useState<FieldTypesEnum>(() =>
        getSelectedValue({
            variationsBlock,
            variationsField,
            selectedField,
            location
        })
    );
    const [variationItems, setVariationItems] = useState<VariationItems[]>(() =>
        getVariationItems(variationsBlock, variationsField)
    );
    const [multiSelectItems, setMultiSelectItems] = useState<FieldItem[]>(
        selectedField?.multiSelectItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );
    const [singleSelectItems, setSingleSelectItems] = useState<FieldItem[]>(
        selectedField?.singleSelectItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );
    const [radioItems, setRadioItems] = useState<FieldItem[]>(
        selectedField?.singleRadioItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );

    const formRef = useRef<HTMLFormElement>(null);
    const [errors, setErrors] = useState<FormFieldsErrors[] | []>([]);
    const [formValues, setFormValues] = useState<{ [key: string]: string }>();
    const [variationsBlockDescription] = useState(() => {
        if (variationsBlock) {
            return variationsBlock[0][0].description || '';
        }

        return '';
    });
    const [variationsFieldDescription] = useState(() => {
        if (variationsField) {
            return variationsField[0][0].description || '';
        }

        return '';
    });

    const isAddingBlockVariations = location === EditorLocationEnum.Block;
    const isAddingClauseVariations = location === EditorLocationEnum.Clause;

    const handleGetFormValues = () => {
        if (formRef.current) {
            const data = serializeForm(formRef.current);
            setFormValues(data);
            const errors: FormFieldsErrors[] = [];
            Object.keys(data).forEach(key => {
                if (!data[key]) {
                    errors.push({ error: 'Field is required', name: key });
                }
            });

            setErrors(errors);

            return errors;
        }
    };

    const onTabChange = (tab: string) => {
        switch (tab) {
            case 'logic':
                const errors = handleGetFormValues();
                if (errors?.length) {
                    return setActiveTab('edit');
                }
                return setActiveTab('logic');
            default:
                setErrors([]);
                setActiveTab(tab);
        }
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const descriptionHtml = stateToHTML(editorState.getCurrentContent());
        const plainText = editorState.getCurrentContent().getPlainText();
        const description = plainText ? descriptionHtml : '';
        const data: { [key: string]: string } =
            location !== EditorLocationEnum.Field ? formValues || {} : serializeForm(event.currentTarget);

        if (isAddingBlockVariations) {
            const generateRadios = Object.keys(data)
                .filter(item => item.startsWith('radio') && item.endsWith('text'))
                .map((radioItem, index) => ({
                    value: {
                        value: showOrHide[index]?.value,
                        type: FieldTypesEnum.Text
                    },
                    text: data[radioItem],
                    isDefault: false
                }));

            blockEvents.addBlockVariationsEvent({
                data: [
                    [
                        {
                            value: generateRadios,
                            type: selectedValue,
                            title: data.value,
                            description,
                            name: '',
                            id: ''
                        }
                    ]
                ],
                blockTitle: blockTitle || '',
                blockId
            });

            return onClose();
        }

        if (isAddingClauseVariations) {
            const generateRadios = Object.keys(data)
                .filter(item => item.startsWith('radio') && item.endsWith('text'))
                .map((radioItem, index) => {
                    const multiSelectedValue = multiSelectValues[index].value;

                    const showOrHideString = getDefaultShowOrHideString(
                        showOrHide[index].value,
                        isAddingClauseVariations
                    );

                    if (multiSelectedValue.length > 0) {
                        let str = '';
                        multiSelectedValue.forEach(({ value }, index) => {
                            str += value;
                            if (index >= 0 && index < multiSelectedValue.length - 1) {
                                str += ',';
                            }
                        });

                        str += `-${showOrHideString.value}`;

                        return {
                            value: {
                                type: FieldTypesEnum.Text,
                                value: str
                            },
                            text: data[radioItem],
                            isDefault: false
                        };
                    }

                    return {
                        value: {
                            value: showOrHide[index]?.value,
                            type: 'Text'
                        },
                        text: data[radioItem],
                        isDefault: false
                    };
                });

            blockEvents.addClauseVariationsEvent({
                data: [
                    [
                        {
                            value: generateRadios,
                            type: selectedValue,
                            title: data.value,
                            name: 'radio',
                            description,
                            blockId,
                            id: ''
                        }
                    ]
                ],
                blockTitle: blockTitle || '',
                clauseId,
                blockId
            });

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.MultiSelect) {
            const generatedMultiSelectItems = multiSelectItems.map(item => ({
                value: item.value,
                id: generateId(),
                text: item.text,
                type: 'Text'
            }));

            setCurrentFieldsEvent(
                selectedField
                    ? previousCurrentFieldsState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    multiSelectItems: generatedMultiSelectItems,
                                    type: selectedValue,
                                    value: data.value,
                                    description,
                                    id: textId
                                }
                              : item
                      )
                    : [
                          ...previousCurrentFieldsState,
                          {
                              multiSelectItems: generatedMultiSelectItems,
                              value: data['value'],
                              type: selectedValue,
                              description,
                              id: textId
                          }
                      ]
            );

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.Select) {
            const generatedSingleSelectItems = singleSelectItems.map(item => ({
                value: item.value,
                id: generateId(),
                text: item.text,
                type: 'Text'
            }));

            setCurrentFieldsEvent(
                selectedField
                    ? previousCurrentFieldsState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    singleSelectItems: generatedSingleSelectItems,
                                    type: FieldTypesEnum.Select,
                                    value: data.value,
                                    description,
                                    id: textId
                                }
                              : item
                      )
                    : [
                          ...previousCurrentFieldsState,
                          {
                              singleSelectItems: generatedSingleSelectItems,
                              type: FieldTypesEnum.Select,
                              value: data.value,
                              description,
                              id: textId
                          }
                      ]
            );

            return onClose();
        }

        if (selectedValue === FieldTypesEnum.Radio) {
            const generatedSingleRadioItems = radioItems.map(item => ({
                value: item.value,
                id: generateId(),
                text: item.text,
                type: 'Text'
            }));

            setCurrentFieldsEvent(
                selectedField
                    ? previousCurrentFieldsState.map(item =>
                          item.id === selectedField.id
                              ? {
                                    singleRadioItems: generatedSingleRadioItems,
                                    type: FieldTypesEnum.Radio,
                                    value: data.value,
                                    description,
                                    id: textId
                                }
                              : item
                      )
                    : [
                          ...previousCurrentFieldsState,
                          {
                              singleRadioItems: generatedSingleRadioItems,
                              type: FieldTypesEnum.Radio,
                              value: data.value,
                              description,
                              id: textId
                          }
                      ]
            );

            return onClose();
        }

        setCurrentFieldsEvent(
            selectedField
                ? previousCurrentFieldsState.map(item =>
                      item.id === selectedField.id
                          ? {
                                question: data.value,
                                placeholder: textId,
                                type: selectedValue,
                                description,
                                id: textId,
                                value: ''
                            }
                          : item
                  )
                : [
                      ...previousCurrentFieldsState,
                      {
                          question: data.value,
                          placeholder: textId,
                          type: selectedValue,
                          description,
                          id: textId,
                          value: ''
                      }
                  ]
        );

        return onClose();
    };

    const handleSelectChange = (value: string) => {
        if ((value === FieldTypesEnum.Text || value === FieldTypesEnum.Date) && activeTab === 'logic') {
            setActiveTab('edit');
        }
        setSelectedValue(value as FieldTypesEnum);
    };

    const handleAddOrRemoveRadioOrMultiSelect = ({
        itemType = 'radio',
        type = 'add',
        index
    }: AddOrRemoveButtonProps) => {
        switch (itemType) {
            case 'radio':
                if (type === 'remove') {
                    return setVariationItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);
                        return [...copyOfState];
                    });
                }

                return setVariationItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        value: {
                            value: '',
                            type: ''
                        },
                        id: generateId(),
                        isDefault: true,
                        text: ''
                    });

                    return [...copyOfState];
                });
            case 'radioItem':
                if (type === 'remove') {
                    return setRadioItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setRadioItems(prevState => {
                    const copyOfState = [...prevState];

                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
            case 'select':
                if (type === 'remove') {
                    return setSingleSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setSingleSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
            case 'multiSelect':
                if (type === 'remove') {
                    return setMultiSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setMultiSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
        }
    };

    const isDisabledEditInput = activeTab === 'logic';

    useEffect(
        () => () => {
            const { resetFieldOrClauseOrBlock, resetMultiSelectValues, resetShowOrHide } = hideOrShowEvents;

            resetFieldOrClauseOrBlock();
            resetMultiSelectValues();
            resetShowOrHide();
        },
        []
    );

    useEffect(() => {
        if (isAddingBlockVariations) {
            const contentState = stateFromHTML(variationsBlockDescription);

            editorEvents.setEditorState(EditorState.createWithContent(contentState));
        }
        if (isAddingClauseVariations) {
            const contentState = stateFromHTML(variationsFieldDescription);

            editorEvents.setEditorState(EditorState.createWithContent(contentState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingBlockVariations, isAddingClauseVariations]);

    const onEditorStateChange = (editorState: EditorState) => {
        editorEvents.setEditorState(editorState);
    };

    return (
        <Container>
            <Form onSubmit={handleSave} ref={formRef}>
                <Content>
                    <Edit
                        variationTitle={getVariationTitle({
                            isAddingClauseVariations,
                            isAddingBlockVariations,
                            variationsBlock,
                            variationsField
                        })}
                        isDisabledEditInput={isDisabledEditInput}
                        handleSelectChange={handleSelectChange}
                        selectedField={selectedField}
                        selectedValue={selectedValue}
                        errors={errors}
                    />
                    {(selectedValue === FieldTypesEnum.Radio || selectedValue === FieldTypesEnum.Select) &&
                        (isAddingBlockVariations || isAddingClauseVariations) && (
                            <VariationsEditor
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                isDisabledEditInput={isDisabledEditInput}
                                isShownLogicTab={activeTab === 'logic'}
                                setVariationItems={setVariationItems}
                                variationItems={variationItems}
                                selectedValue={selectedValue}
                                formValues={formValues}
                                blockId={blockId}
                                clause={clause}
                                errors={errors}
                            />
                        )}
                    {selectedValue === FieldTypesEnum.Select &&
                        !(isAddingBlockVariations || isAddingClauseVariations) && (
                            <SelectMultiSelectRadioEditor
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                setItems={setSingleSelectItems}
                                items={singleSelectItems}
                                fieldType="select"
                                itemType="single"
                            />
                        )}
                    {selectedValue === FieldTypesEnum.Radio &&
                        !(isAddingBlockVariations || isAddingClauseVariations) && (
                            <SelectMultiSelectRadioEditor
                                handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                                setItems={setRadioItems}
                                fieldType="radioItem"
                                items={radioItems}
                                itemType="single"
                            />
                        )}
                    {selectedValue === FieldTypesEnum.MultiSelect && (
                        <SelectMultiSelectRadioEditor
                            handleAddOrRemoveRadioOrMultiSelect={handleAddOrRemoveRadioOrMultiSelect}
                            setItems={setMultiSelectItems}
                            items={multiSelectItems}
                            fieldType="multiSelect"
                            itemType="multi"
                        />
                    )}
                    {activeTab !== 'logic' && (
                        <EditorContainer>
                            <RichTextEditor
                                placeholder="Enter an optional explanation..."
                                onEditorStateChange={onEditorStateChange}
                                editorState={editorState}
                                forDescription
                            />
                        </EditorContainer>
                    )}
                </Content>
                <Footer
                    isAddingClauseVariations={isAddingClauseVariations}
                    isAddingBlockVariations={isAddingBlockVariations}
                    onTabChange={onTabChange}
                    activeTab={activeTab}
                />
            </Form>
        </Container>
    );
};
