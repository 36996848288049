import { GRAY_100, GRAY_20, GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

interface Props {
    disabled?: boolean;
}

export const Container = styled.div<Props>`
    display: grid;
    grid-template-columns: 5fr 1.5fr;
    grid-column-gap: 10px;
    margin-bottom: 10px;
    align-items: flex-end;

    & > div {
        width: 100%;
        display: flex;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;

            & input {
                background-color: ${GRAY_20};
                color: ${GRAY_80};
            }
        `}
`;

export const Label = styled.label`
    width: 100%;
`;

export const Title = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: ${GRAY_100};
    margin-bottom: 4px;
`;
