import type { ReactFCC } from 'types/react';

import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { createEvent, createStore, restore } from 'effector';
import { Input } from 'ui-kit/components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { $user } from 'stores/user/model';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

// TODO: move to stores
const changeDraftName = createEvent<string>();
export const draftSignName = restore<string>(changeDraftName, '');
export const draftSignEmails = createStore<string[]>([]);
export const draftSignMessage = createStore<string>('');

export const Signing: ReactFCC = () => {
    const name = useUnit(draftSignName);

    const { t } = useTranslation();
    const { user } = useUnit($user);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const initialMessage = useMemo(
        () => `${t('constructor.signing.textAreaInitialMessage.firstPart')}
${user?.firstName || 'John'} ${user?.lastName || 'Smith'} ${t('constructor.signing.textAreaInitialMessage.secondPart')}
${user?.firstName || 'John'}`,
        [t, user]
    );

    return (
        <AdaptiveGrid marginBottom="100px" xxl="1fr">
            <InputTitle>{t('constructor.signing.text1')}</InputTitle>
            {/* <EffectorFormTextInputGroup /> */}
            <Input
                onChange={event => {
                    changeDraftName(event.target.value);
                }}
                title={t('constructor.signing.documentName') as string}
                placeholder="Document name"
                defaultValue={name}
            />
            {/* <FormTextArea /> */}
        </AdaptiveGrid>
    );
};
