import { Button } from 'ui-kit/components/buttons/Button';
import { GRAY_60 } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 99;
    padding: 7px 30px;
`;

export const EditorContainer = styled.div<{
    $withSaveButton?: boolean;
}>`
    cursor: text;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;

    ${({ $withSaveButton }) =>
        $withSaveButton &&
        css`
            padding-bottom: 50px;
        `}

    & span {
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        padding: 0;
        margin: 0;
        line-height: 0;
    }

    & .public-DraftEditorPlaceholder-root {
        position: absolute;
        color: #d0d3d9;
    }
`;

export const Container = styled.div`
    border: 1px solid ${GRAY_60};
    border-radius: 8px;
    position: relative;
`;
