import { initializeGenericStore, initializeStoreModal } from 'utils/store';
import { ContractTypeEnum } from 'constants/enums';
import { alertsStores } from 'stores/alerts';
import { combine } from 'effector';

import type {
    CompanyTemplateNamingModalStore,
    UpdateSubscriptionModalStore,
    CompanyUserChangeModalStore,
    DeleteCompanyUserModalStore,
    NewContractDraftModalStore,
    AddPaymentCardModalStore,
    CopyTemplateModalStore,
    LeaveBuilderModalStore,
    BuyContractModalStore,
    ColorPalletModalStore,
    DescriptionModalStore,
    AreYouSureModalStore,
    ComingSoonModalStore,
    TemplateModalStore,
    AddUserModalStore,
    LogicModalStore,
    SubjectType
} from './types';

// * simple modals stores
export const notAuthorizedModalStore = initializeStoreModal();
export const companyUserChangeModal = initializeStoreModal();
export const deleteCompanyUserModal = initializeStoreModal();
export const cookiesModalStore = initializeStoreModal();
export const leaveDraftModalStore = initializeStoreModal();
export const createReminderModalStore = initializeStoreModal();
export const fontsModalStore = initializeStoreModal();
export const loginModalStore = initializeStoreModal();
export const previewModeModalStore = initializeStoreModal();

// * generic modals stores
export const areYouSureModalStore = initializeGenericStore<AreYouSureModalStore>({
    handleSubmit: () => {},
    title: '',
    text: ''
});
export const companyTemplateNamingModalStore = initializeGenericStore<CompanyTemplateNamingModalStore>({});
export const newContractDraftModalStore = initializeGenericStore<NewContractDraftModalStore>({
    type: ContractTypeEnum.Hiring,
    contractName: '',
    contractIndex: 0
});
export const companyUserChangeModalStore = initializeGenericStore<CompanyUserChangeModalStore>({
    user: undefined
});
export const deleteCompanyUserModalStore = initializeGenericStore<DeleteCompanyUserModalStore>({
    handleDeleteCompanyUser: _id => {},
    id: ''
});
export const descriptionModalStore = initializeGenericStore<DescriptionModalStore>({
    backgroundWidth: '',
    description: ''
});
export interface RemoveTrendingModal {
    subject?: SubjectType;
    subjectName?: string;
    id?: string;
}
export const updateSubscriptionModalStore = initializeGenericStore<UpdateSubscriptionModalStore>({
    isFromConstructor: false,
    contractName: '',
    templateId: '',
    isSign: false,
    price: 0
});
export const buyContractModalStore = initializeGenericStore<BuyContractModalStore>({
    contractName: '',
    templateId: '',
    isSign: false,
    price: 0
});
export const leaveBuilderModalStore = initializeGenericStore<LeaveBuilderModalStore>({
    isUpdatingTemplate: false,
    documentName: '',
    config: {}
});
export const comingSoonModalStore = initializeGenericStore<ComingSoonModalStore>({ contractName: '' });
export const addUserModalStore = initializeGenericStore<AddUserModalStore>({ title: '' });
export const addPaymentCardModalStore = initializeGenericStore<AddPaymentCardModalStore>({ title: '' });
export const templateModalStore = initializeGenericStore<TemplateModalStore>({
    documentName: '',
    config: {}
});
export const logicModalStore = initializeGenericStore<LogicModalStore>({});
export const colorPalletModalStore = initializeGenericStore<ColorPalletModalStore>({
    palletToUpdate: {
        second: '',
        fourth: '',
        first: '',
        third: ''
    }
});
export const copyTemplateModalStore = initializeGenericStore<CopyTemplateModalStore>({ templateId: '', from: 'kiara' });

// * combined modals store
export const combinedModalsStore = combine(
    {
        companyTemplateNamingModalStore: companyTemplateNamingModalStore.modal,
        updateSubscriptionModalStore: updateSubscriptionModalStore.modal,
        addPaymentCardModalStore: addPaymentCardModalStore.modal,
        createReminderModalStore: createReminderModalStore.modal,
        notAuthorizedModalStore: notAuthorizedModalStore.modal,
        copyTemplateModalStore: copyTemplateModalStore.modal,
        leaveBuilderModalStore: leaveBuilderModalStore.modal,
        buyContractModalStore: buyContractModalStore.modal,
        colorPalletModalStore: colorPalletModalStore.modal,
        descriptionModalStore: descriptionModalStore.modal,
        previewModeModalStore: previewModeModalStore.modal,
        areYouSureModalStore: areYouSureModalStore.modal,
        comingSoonModalStore: comingSoonModalStore.modal,
        leaveDraftModalStore: leaveDraftModalStore.modal,
        templateModalStore: templateModalStore.modal,
        addUserModalStore: addUserModalStore.modal,
        cookiesModalStore: cookiesModalStore.modal,
        fontsModalStore: fontsModalStore.modal,
        logicModalStore: logicModalStore.modal,
        alertsStores: alertsStores.alertStore
    },
    ({
        companyTemplateNamingModalStore,
        updateSubscriptionModalStore,
        addPaymentCardModalStore,
        createReminderModalStore,
        notAuthorizedModalStore,
        copyTemplateModalStore,
        leaveBuilderModalStore,
        buyContractModalStore,
        colorPalletModalStore,
        descriptionModalStore,
        previewModeModalStore,
        areYouSureModalStore,
        comingSoonModalStore,
        leaveDraftModalStore,
        templateModalStore,
        addUserModalStore,
        cookiesModalStore,
        fontsModalStore,
        logicModalStore,
        alertsStores
    }) => ({
        isCompanyTemplateNamingModalVisible: companyTemplateNamingModalStore[0],
        isUpdateSubscriptionModalVisible: updateSubscriptionModalStore[0],
        isCreateReminderModalVisible: createReminderModalStore.visible,
        isNotAuthorizedModalVisible: notAuthorizedModalStore.visible,
        isAddPaymentCardModalVisible: addPaymentCardModalStore[0],
        isPreviewModeModalVisible: previewModeModalStore.visible,
        isLeaveDraftModalVisible: leaveDraftModalStore.visible,
        isLeaveBuilderModalVisible: leaveBuilderModalStore[0],
        isCopyTemplateModalVisible: copyTemplateModalStore[0],
        isColorPalletModalVisible: colorPalletModalStore[0],
        isBuyContractModalVisible: buyContractModalStore[0],
        isDescriptionModalVisible: descriptionModalStore[0],
        isAlertsModalVisible: Boolean(alertsStores.active),
        isAreYouSureModalVisible: areYouSureModalStore[0],
        isComingSoonModalVisible: comingSoonModalStore[0],
        isCookiesModalVisible: cookiesModalStore.visible,
        isTemplateModalVisible: templateModalStore[0],
        isFontsModalVisible: fontsModalStore.visible,
        isAddUserModalVisible: addUserModalStore[0],
        isLogicModalVisible: logicModalStore[0]
    })
);
