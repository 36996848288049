import { StyledTextInput } from 'components/common/inputs/StyledInput';
import { companyTemplateNamingModalStore } from 'stores/modals';
import { Button } from 'ui-kit/components/buttons/Button';
import { Modal } from 'ui-kit/components/Modal';
import { companyEffects } from 'stores/company';
import { useTranslation } from 'react-i18next';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';
import { useState } from 'react';

import { ButtonsWrapper } from './styles';

export const CompanyTemplateNamingModal: ReactFCC = () => {
    const { closeModal, modal } = companyTemplateNamingModalStore;
    const { t } = useTranslation();
    const [_, { createCompanyTemplatePayload }] = useUnit(modal);
    const [name, setName] = useState('');

    const onSubmit = () => {
        if (createCompanyTemplatePayload) {
            const payload = {
                ...createCompanyTemplatePayload,
                name
            };
            companyEffects.createCompanyTemplate(payload);
        }
    };

    return (
        <Modal title={t('companyTemplateNamingModal.title') as string} onClose={closeModal} width="350px" visible>
            <StyledTextInput
                onChange={e => {
                    setName(e.target.value);
                }}
                placeholder="Template Name"
            />
            <ButtonsWrapper>
                <Button onClick={() => closeModal()} variant="primary">
                    {t('cancel')}
                </Button>
                <Button variant="secondary" onClick={onSubmit}>
                    {t('submit')}
                </Button>
            </ButtonsWrapper>
        </Modal>
    );
};
