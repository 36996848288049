import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { FormInput } from 'ui-kit/components/inputs/FormInput';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { Button } from 'ui-kit/components/buttons/Button';
import { comingSoonModalStore } from 'stores/modals';
import { useFormSchema } from 'hooks/useFormSchema';
import { GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';
import { message } from 'stores/alerts';
import { type FormEvent } from 'react';
import emailjs from '@emailjs/browser';

import { TEMPLATE_ID, PUBLIC_KEY, SERVICE_ID, yupSchema } from './constants';

export const ComingSoonModal: ReactFCC = () => {
    const { closeModal, modal } = comingSoonModalStore;
    const [_, { contractName }] = useUnit(modal);
    const { t } = useTranslation();

    const {
        formState: { isValid },
        getValues,
        control
    } = useFormSchema(yupSchema);

    const sendEmail = async (event: FormEvent) => {
        event.preventDefault();

        try {
            await emailjs.send(SERVICE_ID, TEMPLATE_ID, { from: getValues().email, message: contractName }, PUBLIC_KEY);
            message.success('Success');
            closeModal();
        } catch (error) {
            // TODO: @ts-ignore
            // @ts-ignore
            message.error(error?.text);
        } finally {
        }
    };

    return (
        <Modal title={t('comingSoonModal.title') as string} onClose={closeModal} width="500px" visible>
            <P>
                {t('comingSoonModal.text1')} {contractName.replaceAll('&shy;', '')} {t('comingSoonModal.text2')}
            </P>
            <form onSubmit={sendEmail}>
                <Row marginTop="20px" justifyBetween alignEnd>
                    <ContentWrapper width="70%">
                        <FormInput
                            title={t('comingSoonModal.placeholder') as string}
                            titleColor={GRAY_80}
                            control={control}
                            name="email"
                        />
                    </ContentWrapper>
                    <Button disabled={!isValid} variant="primary" type="submit">
                        {t('comingSoonModal.buttonText')}
                    </Button>
                </Row>
            </form>
        </Modal>
    );
};
