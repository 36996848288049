import type { ReactFCC } from 'types/react';

import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { REGISTER_LINK, AUTH_LINK } from 'constants/routes';
import { Button } from 'ui-kit/components/buttons/Button';
import { notAuthorizedModalStore } from 'stores/modals';
import { GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NotAuthorizedModal: ReactFCC = () => {
    const { closeModal } = notAuthorizedModalStore;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        closeModal();
    };

    const goToLogin = () => {
        handleClose();
        navigate(AUTH_LINK);
    };
    const goToRegister = () => {
        handleClose();
        navigate(REGISTER_LINK);
    };

    return (
        <Modal title={t('notAuthorizedModal.title') as string} onClose={handleClose} width="332px" visible>
            {/* TODO: improve */}
            <span>{t('notAuthorizedModal.subtitle')}</span>

            <MarginWrapper marginBottom="30px" />

            <AdaptiveGrid xxl="1fr auto auto " alignItems="center">
                <Button onClick={goToLogin} variant="primary">
                    {t('notAuthorizedModal.primaryButton')}
                </Button>

                <P color={GRAY_100}>{t('or')}</P>

                <Button onClick={goToRegister} variant="secondary">
                    {t('notAuthorizedModal.secondaryButton')}
                </Button>
            </AdaptiveGrid>
        </Modal>
    );
};
