import type { BlockInterface } from 'types/builder/interface';

import { createEvent, createStore, merge } from 'effector';

import type {
    AddContentEditorStateEventProps,
    ChangeDocumentNameEventProps,
    DeleteClauseVariationsProps,
    ChangeBlockTitleEventProps,
    ChangeShowClauseEventProps,
    DeleteBlockVariationsProps,
    ChangeShowBlockEventProps,
    AddClauseVariationsProps,
    AddBlockVariationsProps,
    AddParagraphEventProps,
    BlockReorderEventProps,
    FieldReorderEventProps,
    DeleteBlockEventProps,
    InitializeBlocksProps,
    AddBlockEventProps
} from './types';

import { deleteClauseVariationsReducer } from './reducers/delete-clause-variations-reducer';
import { addContentEditorStateReducer } from './reducers/add-content-editor-state-reducer';
import { addClauseVariationsReducer } from './reducers/add-clause-variations-reducer';
import { changeShowClauseReducer } from './reducers/change-show-clause-reducer';
import { addParagraphReducer } from './reducers/add-paragraph-reducer';
import { upfrontSummaryEvents } from '../upfrontSummary';
import { questionnaireEvents } from '../questionnaire';
import { signingBlockEvents } from '../signingBlock';
import { designEvents } from '../design';
import { clauseEvents } from '../clause';

// * events
const addBlockEvent = createEvent<AddBlockEventProps>();
const changeBlockTitleEvent = createEvent<ChangeBlockTitleEventProps>();
const deleteBlockEvent = createEvent<DeleteBlockEventProps>();
const addParagraphEvent = createEvent<AddParagraphEventProps>();
const blockReorderEvent = createEvent<BlockReorderEventProps>();
const fieldReorderEvent = createEvent<FieldReorderEventProps>();
const addBlockVariationsEvent = createEvent<AddBlockVariationsProps>();
const deleteBlockVariationsEvent = createEvent<DeleteBlockVariationsProps>();
const addClauseVariationsEvent = createEvent<AddClauseVariationsProps>();
const deleteClauseVariationsEvent = createEvent<DeleteClauseVariationsProps>();
const initializeBlocksEvent = createEvent<InitializeBlocksProps>();
const toggleNumberingEvent = createEvent();
const changeShowBlockEvent = createEvent<ChangeShowBlockEventProps>();
const changeShowClauseEvent = createEvent<ChangeShowClauseEventProps>();
const changeDocumentNameEvent = createEvent<ChangeDocumentNameEventProps>();
const addContentEditorStateEvent = createEvent<AddContentEditorStateEventProps>();
const resetBlocksEvent = createEvent();
const allEvents = merge([
    addBlockEvent,
    changeBlockTitleEvent,
    deleteBlockEvent,
    addParagraphEvent,
    blockReorderEvent,
    fieldReorderEvent,
    addBlockVariationsEvent,
    deleteBlockVariationsEvent,
    addClauseVariationsEvent,
    deleteClauseVariationsEvent,
    initializeBlocksEvent,
    toggleNumberingEvent,
    changeShowBlockEvent,
    changeShowClauseEvent,
    changeDocumentNameEvent,
    questionnaireEvents.addNewSubjectEvent,
    questionnaireEvents.changeSubjectTitleEvent,
    questionnaireEvents.reorderEvent,
    designEvents.changeActiveDesign,
    designEvents.changeDocumentFonts,
    designEvents.changeThemes,
    designEvents.changePalletIndex,
    clauseEvents.changeClauseTitleEvent,
    clauseEvents.addVariant,
    clauseEvents.addClauseEvent,
    clauseEvents.deleteClauseEvent,
    clauseEvents.deleteVariant,
    clauseEvents.makeIsDefaultEvent,
    clauseEvents.toggleNumberingEvent,
    signingBlockEvents.addRowEvent,
    signingBlockEvents.addSectionToRowEvent,
    signingBlockEvents.addSigningBlockEvent,
    signingBlockEvents.deleteSectionEvent,
    signingBlockEvents.reorderFieldsInGroup,
    upfrontSummaryEvents.addSectionToRowEvent
]);

// * stores
const blocks = createStore<{
    blocks: BlockInterface[];
    documentName: string;
    isChanged: boolean;
}>({ documentName: '', isChanged: false, blocks: [] })
    .on(allEvents, state => ({
        ...state,
        isChanged: true
    }))
    .on(changeDocumentNameEvent, (state, { documentName }) => ({ ...state, documentName }))
    .on(initializeBlocksEvent, state => ({
        ...state,
        documentName: '',
        blocks: []
    }))
    .on(addContentEditorStateEvent, addContentEditorStateReducer)
    .on(addBlockEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block] }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(toggleNumberingEvent, state => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            withNumbering: !block.withNumbering
        }))
    }))
    .on(addBlockVariationsEvent, (state, { blockId, data }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: { ...data }, show: false } : block
        )
    }))
    .on(deleteBlockVariationsEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: undefined, show: true } : block
        )
    }))
    .on(deleteClauseVariationsEvent, deleteClauseVariationsReducer)
    .on(addClauseVariationsEvent, addClauseVariationsReducer)
    .on(addParagraphEvent, addParagraphReducer)
    .on(deleteBlockEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.filter(block => block.id !== blockId)
    }))
    .on(changeBlockTitleEvent, (state, { blockId, title }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, title } : block))
    }))
    .on(blockReorderEvent, (state, { items }) => ({
        ...state,
        blocks: items
    }))
    .on(fieldReorderEvent, (state, { blockId, items }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, groups: [{ fields: items }] } : block))
    }))
    .on(changeShowBlockEvent, (state, { blockId, show }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId && block.variationsBlock
                ? {
                      ...block,
                      show
                  }
                : block
        )
    }))
    .on(changeShowClauseEvent, changeShowClauseReducer)
    .on(resetBlocksEvent, () => ({
        documentName: '',
        isChanged: false,
        blocks: []
    }));

// * exports
export const blockStores = {
    blocks
};

export const blockEvents = {
    deleteClauseVariationsEvent,
    deleteBlockVariationsEvent,
    addContentEditorStateEvent,
    addClauseVariationsEvent,
    addBlockVariationsEvent,
    changeDocumentNameEvent,
    changeBlockTitleEvent,
    initializeBlocksEvent,
    changeShowClauseEvent,
    toggleNumberingEvent,
    changeShowBlockEvent,
    addParagraphEvent,
    blockReorderEvent,
    fieldReorderEvent,
    deleteBlockEvent,
    resetBlocksEvent,
    addBlockEvent
};
