import { createNotifyingSuccessEffect, createNotifyingErrorEffect } from 'utils/store';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'api';

// * events
const resetTemplates = createEvent();

const resetTemplate = createEvent();

// * effects
const getTemplates = createNotifyingErrorEffect({ handler: async () => await API.ownTemplates.getTemplates() });

const createTemplate = createEffect({
    handler: async (data: KIARA.TemplateRequest) => await API.ownTemplates.createTemplate(data)
});

const updateTemplate = createNotifyingSuccessEffect({
    handler: async ({ data, id }: { data: KIARA.TemplateRequest; id: string }) =>
        await API.ownTemplates.updateTemplate(id, data)
});

const deleteTemplate = createNotifyingSuccessEffect({
    handler: async (id: string) => await API.ownTemplates.deleteTemplate(id)
});

const getTemplate = createNotifyingErrorEffect({
    handler: async (id: string) => await API.ownTemplates.getTemplate(id)
});

// * stores
const templates = createStore<KIARA.TemplateResponse[]>([])
    .on(getTemplates.doneData, (_, payload) => payload)
    .reset(resetTemplates);

const template = createStore<KIARA.TemplateResponse | null>(null)
    .on(getTemplate.doneData, (_, payload) => payload)
    .reset(resetTemplate);

// * exports
export const ownTemplatesEvents = {
    resetTemplates,
    resetTemplate
};

export const ownTemplatesEffects = {
    deleteTemplate,
    updateTemplate,
    createTemplate,
    getTemplates,
    getTemplate
};

export const ownTemplatesStores = {
    templates,
    template
};
