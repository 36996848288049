import type { ReactFCC } from 'types/react';

import { type DraftEditorCommand, type DraftHandleValue, type EditorState, RichUtils, Editor } from 'draft-js';
import { type MouseEventHandler, type KeyboardEvent, useRef } from 'react';

import { EditorContainer, StyledButton, Container } from './styles';
import { customStyleMap } from './constants';
import { Toolbar } from './ToolBar/ToolBar';

interface Props {
    onEditorStateChange: (editorState: EditorState) => void;
    onSave?: MouseEventHandler<HTMLButtonElement>;
    editorState: EditorState;
    forDescription?: boolean;
    placeholder?: string;
    readOnly?: boolean;
}

export const RichTextEditor: ReactFCC<Props> = ({
    onEditorStateChange,
    forDescription,
    editorState,
    placeholder,
    readOnly,
    onSave
}) => {
    const editorRef = useRef<Editor>(null);

    const handleKeyCommand = (command: DraftEditorCommand) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorStateChange(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const handleReturn = (event: KeyboardEvent, editorState: EditorState): DraftHandleValue => {
        if (event.key === 'Enter') {
            onEditorStateChange(RichUtils.insertSoftNewline(editorState));

            return 'handled';
        }

        return 'not-handled';
    };

    const onFocus = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    const withSaveButton = onSave && !readOnly;

    return (
        <Container>
            {withSaveButton && (
                <StyledButton variant="primary" onClick={onSave}>
                    Save
                </StyledButton>
            )}
            {(withSaveButton || forDescription) && (
                <Toolbar onEditorStateChange={onEditorStateChange} editorState={editorState} />
            )}
            <EditorContainer $withSaveButton={withSaveButton} onClick={onFocus}>
                <Editor
                    placeholder={placeholder || 'Enter a text...'}
                    handleKeyCommand={handleKeyCommand}
                    customStyleMap={customStyleMap}
                    onChange={onEditorStateChange}
                    handleReturn={handleReturn}
                    editorState={editorState}
                    readOnly={readOnly}
                    ref={editorRef}
                />
            </EditorContainer>
        </Container>
    );
};
