import { DraggableTypesEnum } from 'constants/builder/enums';

import type { QuestionnaireItems, QuestionnaireItem } from '../types';

interface Props {
    questions: QuestionnaireItems;
    question: QuestionnaireItem;
    value: string[] | string;
    blockId?: string;
    fieldId: string;
    answer?: string;
}

export const changeBlockVariationsHelper = ({
    questions,
    question,
    fieldId,
    blockId,
    answer,
    value
}: Props): QuestionnaireItem | undefined => {
    if (question.id === fieldId && question.variationsBlock) {
        return {
            ...question,
            variationsBlock: [
                [
                    {
                        ...question.variationsBlock[0][0],
                        value: question.variationsBlock[0][0].value.map(item =>
                            item.text === value ? { ...item, isDefault: true } : { ...item, isDefault: false }
                        )
                    }
                ]
            ]
        };
    }

    // This logic determines whether or not to show all clauses inside this block based on the value and 'show' answer are present.
    if (question.questionType === DraggableTypesEnum.Clause && question.blockId === blockId) {
        return {
            ...question,
            isShown: value && answer === 'show' ? true : false
        };
    }

    // This logic hides all fields when the block has no value or the answer is 'hide' or show fields based on the clause value.
    if (question.questionType === DraggableTypesEnum.Field && question.blockId === blockId) {
        if (!value || answer === 'hide') {
            return {
                ...question,
                isShown: false
            };
        } else {
            const parent = questions.find(
                item => item.clauseId === question.clauseId && item.questionType === DraggableTypesEnum.Clause
            );
            if (parent) {
                return {
                    ...question,
                    isShown: parent?.variationsField?.[0][0].value[0].isDefault
                };
            } else {
                return {
                    ...question,
                    isShown: true
                };
            }
        }
    }
};
