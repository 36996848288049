import type { ReactFCC } from 'types/react';

import { StyledAddFieldButton } from './styles';
import { PlusIcon } from './static_assets';

interface Props {
    onClick: () => void;
}

export const AddFieldButton: ReactFCC<Props> = ({ onClick }) => (
    <StyledAddFieldButton onClick={onClick}>
        <PlusIcon />
        Field
    </StyledAddFieldButton>
);
