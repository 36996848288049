import { LANGUAGE_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';

// * events
export const changeLanguageEvent = createEvent<string>();

// * stores
export const $currentLanguageCode = createStore<string>('du').on(changeLanguageEvent, (_, payload) => payload);
persist({ key: LANGUAGE_LOCAL_STORAGE_NAME, store: $currentLanguageCode });
