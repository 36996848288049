import type { HTMLAttributeAnchorTarget } from 'react';

import { type StyledLinkProps, StyledLink } from 'ui-kit/components/links/ExternalLink/styles';
import { type ReactFCC } from 'types/react';

export interface ExternalLinkProps extends StyledLinkProps {
    target?: HTMLAttributeAnchorTarget;
    href: string;
}

export const ExternalLink: ReactFCC<ExternalLinkProps> = ({ target = '_blank', href, children = href, ...props }) => (
    <StyledLink target={target} href={href} {...props}>
        {children}
    </StyledLink>
);
