import type { SocialMediaEnum } from 'constants/enums';

import { BASE_URL } from 'constants/services';
import axios from 'api/axios';

export const createUser = (data: API.SignUpDto) =>
    axios<API.SecuredUser>(
        {
            url: '/auth/signup',
            data
        },
        false
    );

export const authenticateUser = (data: API.LoginDto) =>
    axios<API.AuthResponseDto>(
        {
            url: '/auth/login',
            data
        },
        false
    );

export const checkEmailForExistence = (data: { email: string }) =>
    axios<{ message: string }>({
        url: '/auth/check-email',
        data
    });

export const socialMediaAuthorize = (name: SocialMediaEnum) => window.location.replace(`${BASE_URL}/auth/${name}`);
export const authenticateUserWithSocialMedia = (name: SocialMediaEnum) =>
    axios<API.AuthResponseDto>(
        {
            url: `/auth/${name}`,
            method: 'GET'
        },
        false
    );

export const recoverPassword = (data: API.ForgotPasswordDto) =>
    axios<API.AuthResponseDto>(
        {
            url: '/auth/forgot-password',
            data
        },
        false
    );

export const updateUser = (data: API.UpdateUserDto) =>
    axios<API.SecuredUser>({
        url: '/users/update/me',
        method: 'PATCH',
        data
    });

export const updateUserPassword = (data: API.UpdatePasswordDto) =>
    axios<API.SuccessAnswer>({
        url: '/users/me/update-password',
        method: 'PATCH',
        data
    });

export const getMe = () =>
    axios<API.SecuredUser>({
        url: '/users/me',
        method: 'GET'
    });

export const changeUserRole = (data: { userId: string; role: string }) =>
    axios({
        url: 'users/change-role',
        data
    });

export const verifyAccount = (token: string) =>
    axios<API.AuthResponseDto>(
        {
            url: `users/verify/${token}`,
            method: 'GET'
        },
        false
    );

export const changePassword = (data: { password: string; token: string }) =>
    axios<API.AuthResponseDto>(
        {
            url: 'auth/change-password',
            data
        },
        false
    );
