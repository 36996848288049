import { GRAY_100, GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Title = styled.p`
    color: ${GRAY_100};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
`;

export const Text = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${GRAY_80};
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 28px;
`;
