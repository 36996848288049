export const getColorChoosingText = (from: string) => {
    switch (from) {
        case 'first':
            return 'Choose a primary color';
        case 'second':
            return 'Choose a secondary color';
        case 'third':
            return 'Choose a dark text color';
        case 'fourth':
            return 'Choose a light text color';
        default:
            return 'Click and choose your colors';
    }
};
