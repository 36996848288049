import { lazy } from 'react';

// PrivatePages
export const LazyLoadedDocuments = lazy(() => import('../pages/Dashboard/Documents'));
export const LazyLoadedDrafts = lazy(() => import('../pages/Dashboard/Drafts'));
export const LazyLoadedTemplates = lazy(() => import('../pages/Dashboard/Templates'));
export const LazyLoadedSettings = lazy(() => import('../pages/Dashboard/Settings'));
export const LazyLoadedBuilder = lazy(() => import('../pages/Builder'));
export const LazyLoadedMagic = lazy(() => import('../pages/Magic'));

// Auth
export const LazyLoadedRegister = lazy(() => import('../pages/auth/Register'));
export const LazyLoadedLogin = lazy(() => import('../pages/auth/Login'));
export const LazyLoadedForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
export const LazyLoadedCheckEmail = lazy(() => import('../pages/auth/CheckEmail'));
export const LazyLoadedResetPassword = lazy(() => import('../pages/auth/ResetPassword'));

export const LazyLoadedJoinToCompany = lazy(() => import('../pages/JoinToCompany'));

// PublicPages
export const LazyLoadedMain = lazy(() => import('../pages/Main'));
export const LazyLoadedSocialMedia = lazy(() => import('../pages/auth/SocialMedia'));
export const LazyLoadedPrices = lazy(() => import('../pages/Prices'));
export const LazyLoadedPrivacyPolicy = lazy(() => import('../pages/legal/PrivacyPolicy'));
export const LazyLoadedTermsOfUse = lazy(() => import('../pages/legal/TermsOfUse'));
export const LazyLoadedConstructor = lazy(() => import('../pages/Constructor'));

//ThankYou
export const LazyLoadedThankYouContact = lazy(() => import('../pages/thankYou/Contact'));
export const LazyLoadedThankYouRegistration = lazy(() => import('../pages/thankYou/Registration'));
export const LazyLoadedVerifyAccount = lazy(() => import('../pages/thankYou/VerifyAccount'));
export const LazyLoadedPasswordReset = lazy(() => import('../pages/thankYou/PasswordReset'));
export const LazyLoadedPasswordChanged = lazy(() => import('../pages/thankYou/PasswordChanged'));

export const LazyLoadedMollieSuccess = lazy(() => import('../pages/MollieSuccess'));
