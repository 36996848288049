import axios from 'api/axios';

export const copyTemplate = async (data: KIARA.CopyTemplateRequest) => {
    const { templateName, templateId, companyId, location } = data;

    const newData = {
        templateName,
        companyId,
        location
    };

    return axios(
        {
            url: `/templates/copy/${templateId}`,
            method: 'PATCH',
            data: newData
        },
        true
    );
};
