import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Input } from 'ui-kit/components/inputs/Input';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type ChangeEvent, useRef } from 'react';
import { type ReactFCC } from 'types/react';

interface Props {
    handleChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
    isDisabledEditInput: boolean;
    error?: string;
    index: number;
    text: string;
    name: string;
}

export const InputWithTooltip: ReactFCC<Props> = ({ isDisabledEditInput, handleChange, index, error, text, name }) => {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={text}>
            <Input
                placeholder="Enter a possible answer here"
                onChange={e => handleChange(e, index)}
                disabled={!!isDisabledEditInput}
                reference={ref}
                error={error}
                value={text}
                name={name}
                type="text"
                isEllipsis
            />
        </Tooltip>
    );
};
