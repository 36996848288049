import { dataPickerStyles } from 'ui-kit/components/DatePicker/styles';
import { createGlobalStyle } from 'styled-components';

import { APP_FONT_FAMILY } from './appFontFamily';
import { GRAY_60 } from './colors';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body,
    html,
    #root {
        height: 100%;
    }

    body {
        margin: 0;
        font-family: ${APP_FONT_FAMILY};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body,
    div,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    em,
    img,
    strong,
    ol,
    ul,
    li,
    form,
    label,
    legend,
    table,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    figure,
    figcaption,
    footer,
    header,
    nav,
    section {
        margin: 0;
        padding: 0;
        border: 0;
    }

    ol,
    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    input, button, textarea {
        font-family: ${APP_FONT_FAMILY};
    }

    @-moz-document url-prefix() {
        /* Disable scrollbar Firefox */
        html.scroll-hide {
            scrollbar-width: none;
        }
    }

    html.scroll-hide body {
        margin: 0; /* remove default margin */
        scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
        -ms-overflow-style: none; /* Disable scrollbar IE 10+ */
        overflow-y: scroll;
    }

    html.scroll-hide body::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${GRAY_60};
        border-radius: 4px;
    }

    ${dataPickerStyles};
`;
