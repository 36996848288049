import { AdaptiveGrid } from 'components/wrappers/grid/GridWrapper';
import { Button } from 'ui-kit/components/buttons/Button';
import { buyContractModalStore } from 'stores/modals';
import { P } from 'ui-kit/components/typography/P';
import { Modal } from 'ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { type ReactFCC } from 'types/react';
import { pdfEvents } from 'stores/pdf';

import { Subtitle } from './styles';

export const BuyContractModal: ReactFCC = () => {
    const { closeModal } = buyContractModalStore;
    const { t } = useTranslation();

    const createContract = () => {
        pdfEvents.togglePdfGenerationProcessVisible();
    };

    return (
        <Modal title={t('buyContractModal.title') as string} onClose={closeModal} visible>
            <Subtitle>{t('buyContractModal.subtitle')}</Subtitle>
            <AdaptiveGrid alignItems="center" xxl="1fr auto 1fr" marginTop="30px">
                <Button onClick={() => closeModal()} variant="primary">
                    {t('no')}
                </Button>
                <P>{t('or')}</P>
                <Button onClick={createContract} variant="secondary">
                    {t('yes')}
                </Button>
            </AdaptiveGrid>
        </Modal>
    );
};
