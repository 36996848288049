import type { ReactFCC } from 'types/react';

import { combinedModalsStore } from 'stores/modals';
import { useUnit } from 'effector-react';

import {
    CompanyTemplateNamingModal,
    UpdateSubscriptionModal,
    AddPaymentCardModal,
    CreateReminderModal,
    NotAuthorizedModal,
    CopyTemplateModal,
    LeaveBuilderModal,
    NotificationModal,
    BuyContractModal,
    ColorPalletModal,
    DescriptionModal,
    PreviewModeModal,
    AreYouSureModal,
    ComingSoonModal,
    LeaveDraftModal,
    TemplateModal,
    AddUserModal,
    CookiesModal,
    FontsModal,
    LogicModal
} from './index';

export const Modal: ReactFCC = () => {
    const {
        isCompanyTemplateNamingModalVisible,
        isUpdateSubscriptionModalVisible,
        isAddPaymentCardModalVisible,
        isCreateReminderModalVisible,
        isNotAuthorizedModalVisible,
        isCopyTemplateModalVisible,
        isLeaveBuilderModalVisible,
        isBuyContractModalVisible,
        isColorPalletModalVisible,
        isDescriptionModalVisible,
        isPreviewModeModalVisible,
        isAreYouSureModalVisible,
        isComingSoonModalVisible,
        isLeaveDraftModalVisible,
        isTemplateModalVisible,
        isAddUserModalVisible,
        isCookiesModalVisible,
        isAlertsModalVisible,
        isFontsModalVisible,
        isLogicModalVisible
    } = useUnit(combinedModalsStore);

    return (
        <>
            {isPreviewModeModalVisible && <PreviewModeModal />}
            {isAlertsModalVisible && <NotificationModal />}
            {isNotAuthorizedModalVisible && <NotAuthorizedModal />}
            {isUpdateSubscriptionModalVisible && <UpdateSubscriptionModal />}
            {isCookiesModalVisible && <CookiesModal />}
            {isLogicModalVisible && <LogicModal />}
            {isAreYouSureModalVisible && <AreYouSureModal />}
            {isTemplateModalVisible && <TemplateModal />}
            {isLeaveBuilderModalVisible && <LeaveBuilderModal />}
            {isFontsModalVisible && <FontsModal />}
            {isColorPalletModalVisible && <ColorPalletModal />}
            {isComingSoonModalVisible && <ComingSoonModal />}
            {isBuyContractModalVisible && <BuyContractModal />}
            {isLeaveDraftModalVisible && <LeaveDraftModal />}
            {isCompanyTemplateNamingModalVisible && <CompanyTemplateNamingModal />}
            {isDescriptionModalVisible && <DescriptionModal />}
            {isCreateReminderModalVisible && <CreateReminderModal />}
            {isAddUserModalVisible && <AddUserModal />}
            {isAddPaymentCardModalVisible && <AddPaymentCardModal />}
            {isCopyTemplateModalVisible && <CopyTemplateModal />}
        </>
    );
};
