import { descriptionModalStore } from 'stores/modals';
import { Modal } from 'ui-kit/components/Modal';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import { DescriptionWrapper } from './styles';

export const DescriptionModal: ReactFCC = () => {
    const { closeModal, modal } = descriptionModalStore;
    const [_, { description }] = useUnit(modal);

    return (
        <Modal onClose={closeModal} width="700px" visible>
            <DescriptionWrapper dangerouslySetInnerHTML={{ __html: description }} />
        </Modal>
    );
};
