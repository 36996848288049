import { draftSignMessage, draftSignEmails, draftSignName } from 'pages/Constructor/steps/Signing';
import { documentsEffects } from 'stores/documents';
import { draftsStores } from 'stores/drafts';
import { sample } from 'effector';

import { pdfEffects, pdfEvents, pdfStores } from './index';

pdfEffects.convertToPdfAndCreateContract.failData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

pdfEffects.convertToPdfAndCreateContract.doneData.watch(() => {
    pdfEvents.togglePdfGenerationProcessVisible();
});

sample({
    fn: async ({ documentName, pdfData, message, fileId, draft }) => {
        if (pdfData && fileId && draft) {
            const { pdf } = pdfData;

            try {
                await documentsEffects.createDocument({
                    name: documentName,
                    massage: message,
                    description: '',
                    draft: draft.id,
                    pdf: fileId
                });
                pdf.save(documentName);
                // TODO: catch
            } catch {}
        }
    },
    source: {
        documentName: draftSignName,
        pdfData: pdfStores.pdfData,
        message: draftSignMessage,
        draft: draftsStores.draft,
        fileId: pdfStores.fileId,
        emails: draftSignEmails
    },
    clock: pdfEffects.convertToPdfAndCreateContract.doneData
});
