import { createNotifyingSuccessEffect, createNotifyingErrorEffect } from 'utils/store';
import { USER_LOCAL_STORAGE_NAME } from 'constants/local-storage';
import { createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/local';
import { API } from 'api';

// * events
export const logoutEvent = createEvent();
export const setUserEvent = createEvent<API.AuthResponseDto>();

// * effects
export const loadTokenFx = createNotifyingErrorEffect({
    handler: async (data: API.LoginDto) => await API.user.authenticateUser(data)
});

export const verifyAccountFx = createNotifyingErrorEffect({
    handler: async (token: string) => await API.user.verifyAccount(token)
});

export const createUserFx = createNotifyingErrorEffect({
    handler: async (data: API.SignUpDto) => {
        const user = await API.user.createUser(data);

        return { user };
    }
});

export const checkEmailForExistenceFx = createNotifyingErrorEffect({
    handler: async (data: { email: string }) => await API.user.checkEmailForExistence(data)
});

export const recoverPasswordFx = createNotifyingErrorEffect({
    handler: async (data: API.ForgotPasswordDto) => await API.user.recoverPassword(data)
});

export const updateUserFx = createNotifyingErrorEffect({
    handler: async (data: API.UpdateUserDto) => await API.user.updateUser(data)
});

export const updateUserPasswordFx = createNotifyingSuccessEffect({
    handler: async (data: API.UpdatePasswordDto) => await API.user.updateUserPassword(data)
});

export const getMeFx = createNotifyingErrorEffect({
    handler: async () => await API.user.getMe()
});

export const changeUserRoleFx = createNotifyingErrorEffect({
    handler: async (data: { userId: string; role: string }) => await API.user.changeUserRole(data)
});

export const changePasswordFx = createNotifyingErrorEffect({
    handler: async (data: { password: string; token: string }) => await API.user.changePassword(data)
});

// * stores
export const $user = createStore<Partial<API.AuthResponseDto>>({})
    .on(loadTokenFx.doneData, (_, user) => user)
    .on(createUserFx.doneData, (_, user) => user)
    .on(updateUserFx.doneData, (store, user) => ({ ...store, user }))
    .on(getMeFx.doneData, (store, user) => ({ ...store, user }))
    .on(logoutEvent, () => ({}))
    .on(setUserEvent, (_, user) => user);

persist({ key: USER_LOCAL_STORAGE_NAME, store: $user });

export const $doesEmailExist = createStore(false)
    .on(checkEmailForExistenceFx.fail, (_, _data) => false)
    .on(checkEmailForExistenceFx.done, (_, _data) => true);
