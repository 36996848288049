export const defaultFonts = [
    { second: '+ Poppins', first: 'Helvetica' },
    { second: '+ Open Sans', first: 'Helvetica' },
    { first: 'Helvetica', second: '+ Inter' },
    { second: '+ Open Sans', first: 'Poppins' },
    { second: '+ Open Sans', first: 'Inter' }
];

export const displayedFontFamilies = [
    'Helvetica',
    'Arial',
    'Calibri',
    'Open Sans',
    'Lato',
    'Source Sans Pro',
    'Work Sans',
    'Times New Roman',
    'Libre Baskerville',
    'Crimson Text',
    'EB Garamond',
    'Montserrat',
    'Baloo',
    'DM Serif Display',
    'Poppins',
    'Mulish',
    'Quicksand',
    'Inter'
];

export const fontPairingOptions = defaultFonts.map(({ second, first }) => ({
    label: `${first} ${second}`,
    value: `${first} ${second}`
}));

export const fontOptions = displayedFontFamilies.map(item => ({
    label: item,
    value: item
}));
