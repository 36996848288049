import { cookiesModalStore } from 'stores/modals';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import { CookieConsentWrapper, HighlightedText, TextContainer } from './styles';

export const CookieBanner = () => {
    const { t } = useTranslation();

    const openCookiesModal = () => {
        cookiesModalStore.openModal();
    };

    return (
        <CookieConsentWrapper>
            <CookieConsent
                buttonClasses="AcceptButton"
                cookieName="cookieConsent"
                buttonText={t('accept')}
                disableButtonStyles
                location="bottom"
                disableStyles
                expires={150}
            >
                <TextContainer>
                    {t('cookieBanner.text')}{' '}
                    <HighlightedText onClick={openCookiesModal}>{t('cookieBanner.link')}</HighlightedText>.
                </TextContainer>
            </CookieConsent>
        </CookieConsentWrapper>
    );
};
