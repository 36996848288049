import styled from 'styled-components';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;
