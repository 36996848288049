import type { AbsoluteWrapperProps } from 'components/wrappers/grid/AbsoluteWrapper/types';
import type { ZIndex } from 'types/styles';

import styled from 'styled-components';

export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
    position: absolute;
    ${({ bottom, top }) => (top ? `top: ${top};` : bottom ? `bottom: ${bottom};` : `top: 0;`)};
    ${({ right, left }) => (left ? `left: ${left};` : right ? `right: ${right};` : `left: 0;`)};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    display: ${({ isClosed }) => (isClosed ? 'none' : 'block')};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;

export const AbsoluteCenterAlignment = styled.div<ZIndex>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;
