import axios from 'api/axios';

export const createDraft = (data: KIARA.UserDraftsRequest) =>
    axios<{
        isDeleted: boolean;
        createdAt: string;
        deletedAt: string;
        updatedAt: string;
        draft: string;
        user: string;
        id: string;
    }>({
        url: 'user-drafts',
        data
    });

export const updateDraft = (data: KIARA.UserDraftsRequest, id: string) =>
    axios<API.ErrorResponse>({
        url: `user-drafts/${id}`,
        method: 'PATCH',
        data
    });

export const getDrafts = () =>
    axios<KIARA.UserDraftsResponse[]>({
        url: 'user-drafts/my',
        method: 'GET'
    });

export const getDraft = (id: string) =>
    axios<KIARA.UserDraftsResponse>({
        url: `user-drafts/${id}`,
        method: 'GET'
    });

export const deleteDraft = (id: string) =>
    axios<KIARA.SuccessAnswer>({
        url: `user-drafts/${id}`,
        method: 'DELETE'
    });
