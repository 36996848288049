import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import { WHITE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

export const Title = styled.h3`
    ${disableDefaultHStyleMixin};
    margin-right: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
`;

interface HasBorder {
    $hasBorder: boolean;
}

export const ModalHeader = styled.div<HasBorder>`
    padding: 16px 16px 8px 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: sticky;
    top: 0;
    background-color: ${WHITE};
    z-index: 100;

    ${({ $hasBorder }) =>
        $hasBorder &&
        css`
            border-bottom: 1px solid #f3f3f3;
        `}
`;

export const ModalBody = styled(ContentWrapper)`
    height: 100%;
    overflow-y: auto;
    word-wrap: break-word;
    flex: 1;
    padding: 16px;
`;

export const ModalFooter = styled.div<HasBorder>`
    padding: 16px;
    background-color: ${WHITE};

    ${({ $hasBorder }) =>
        $hasBorder &&
        css`
            border-top: 1px solid #f3f3f3;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        `}
`;

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
`;
