import type { Color } from 'types/styles';

import { PURPLE_100 } from 'ui-kit/GlobalStyle/colors';
import { lg } from 'ui-kit/GlobalStyle/screansSizes';
import { css } from 'styled-components';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ellipsisRowMixin = css`
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const disableDefaultInputStyleMixin = css`
    outline: none;
    appearance: none;
    border: none;
`;

export const disableDefaultHStyleMixin = css`
    padding: 0;
    margin: 0;
`;

export const bigScreenDisplayNoneMixin = css`
    @media (min-width: ${lg}) {
        display: none;
    }
`;

export const disableScrollbarMixin = css`
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const absoluteCenterAlignmentMixin = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const absoluteVerticalCenterAlignmentMixin = css`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`;

export const absoluteHorizontalCenterAlignmentMixin = css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

export const inheritTextStyleMixin = css`
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
`;

export const customPrimaryTextColorMixin = css<Color>`
    color: ${({ color }) => color || PURPLE_100};
`;

export const primaryShadowMixin = css`
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.06);
`;

export const secondaryShadowMixin = css`
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.06);
`;

export const hideScrollbarMixin = css`
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const hiddenInputMixin = css`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
