import { questionnaireEvents } from 'stores/builder/questionnaire';
import { blockEvents } from 'stores/builder/block';
import { templateModalStore } from 'stores/modals';
import { TEMPLATES_LINK } from 'constants/routes';
import { browserHistory } from 'browserHistory';
import { sample } from 'effector';

import { ownTemplatesEffects, ownTemplatesEvents } from './index';

ownTemplatesEffects.updateTemplate.doneData.watch(() => {
    browserHistory.push(TEMPLATES_LINK);

    blockEvents.resetBlocksEvent();
    questionnaireEvents.resetQuestionnaireEvent();
    ownTemplatesEvents.resetTemplate();
});

ownTemplatesEffects.createTemplate.doneData.watch(() => {
    templateModalStore.closeModal();
    browserHistory.push(TEMPLATES_LINK);
});

ownTemplatesEffects.updateTemplate.doneData.watch(() => {
    templateModalStore.closeModal();
    browserHistory.push(TEMPLATES_LINK);
    ownTemplatesEvents.resetTemplate();
    blockEvents.resetBlocksEvent();
    questionnaireEvents.resetQuestionnaireEvent();
});

sample({
    fn: () => {
        ownTemplatesEffects.getTemplates(null);
        blockEvents.resetBlocksEvent();
    },
    clock: [ownTemplatesEffects.deleteTemplate.doneData]
});
