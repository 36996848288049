import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { useEffect, useState, useMemo, useRef } from 'react';
import { EditorLocationEnum } from 'constants/builder/enums';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Input } from 'ui-kit/components/inputs/Input';
import { editorStores } from 'stores/builder/editor';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type SingleValue } from 'react-select';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import { inputTypesForClause, inputTypesForField, icons } from './constants';
import { type FormFieldsErrors } from '../../types';
import { Container, Label, Title } from './styles';

// TODO: any
interface Props {
    handleSelectChange: (value: string) => void;
    isDisabledEditInput: boolean;
    errors: FormFieldsErrors[];
    variationTitle: string;
    selectedValue: string;
    selectedField?: any;
}

export const Edit: ReactFCC<Props> = ({
    isDisabledEditInput,
    handleSelectChange,
    variationTitle,
    selectedValue,
    selectedField,
    errors
}) => {
    const location = useUnit(editorStores.location);
    const ref = useRef<HTMLInputElement>(null);
    const [inputText, setInputText] = useState('');

    const error = useMemo(() => errors.find(item => item.name === 'value' && item.name)?.error, [errors]);

    const isAddingClauseVariations = useMemo(() => location === EditorLocationEnum.Clause, [location]);
    const isAddingBlockVariations = useMemo(() => location === EditorLocationEnum.Block, [location]);

    useEffect(() => {
        setInputText(selectedField?.value || selectedField?.question || variationTitle || '');
    }, [selectedField, variationTitle]);

    return (
        <Container>
            <Label htmlFor="value">
                <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={inputText}>
                    <Input
                        onChange={e => {
                            setInputText(e.target.value);
                        }}
                        placeholder="Enter your question here..."
                        disabled={!!isDisabledEditInput}
                        defaultValue={inputText}
                        title="Question"
                        reference={ref}
                        error={error}
                        name="value"
                        type="text"
                        isEllipsis
                    />
                </Tooltip>
            </Label>
            <Column width="202px" alignStart>
                <Title>Type</Title>
                <Select
                    defaultValue={{
                        icon: icons[selectedValue as keyof typeof icons],
                        label: selectedValue,
                        value: selectedValue
                    }}
                    onChange={value => {
                        const newValue = value as SingleValue<SelectValue>;

                        handleSelectChange(newValue?.label || '');
                    }}
                    options={
                        isAddingClauseVariations || isAddingBlockVariations ? inputTypesForClause : inputTypesForField
                    }
                    disabled={!!isDisabledEditInput}
                    isSearchable={false}
                />
            </Column>
        </Container>
    );
};
