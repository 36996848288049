import { PURPLE_100, GRAY_100, GRAY_40, ORANGE } from 'ui-kit/GlobalStyle/colors';
import { P } from 'ui-kit/components/typography/P';
import styled from 'styled-components';
import media from 'utils/media-query';

export const CookieConsentWrapper = styled.div`
    & .CookieConsent {
        position: fixed;
        background: ${PURPLE_100};
        color: ${GRAY_40};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        padding-bottom: 22px;
        z-index: 998;
    }

    & .AcceptButton {
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        background: ${ORANGE};
        border: 0;
        border-radius: 8px;
        color: ${GRAY_40};
        padding-left: 26px;
        padding-right: 26px;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-left: 40px;
    }

    ${media.phone`
        & .CookieConsent {
            padding: 24px;
            flex-direction: column;
            align-items: start;
        }

        & .AcceptButton {
            margin-left: 0;
        }
    `}
`;

export const TextContainer = styled(P)`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 596px;

    ${media.tablet`
        max-width: 470px;
    `}

    ${media.phone`
        max-width: 100%;
        margin-bottom: 32px;
    `}
`;

export const HighlightedText = styled.span`
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
        color: ${GRAY_100};
    }
`;
