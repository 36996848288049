export const xs = '480px';
export const sm = '576px';
export const md = '768px';
export const lg = '992px';
export const xl = '1200px';
export const xxl = '1600px';

export const xs_1 = '479px';
export const sm_1 = '575px';
export const md_1 = '767px';
export const lg_1 = '991px';
export const xl_1 = '1199px';
export const xxl_1 = '1599px';
