import { type ReactFCC } from 'types/react';
import { useState } from 'react';

import { Container, Ball } from './styles';

export interface SwitchProps {
    onChange?: (active: boolean) => void;
    defaultState?: boolean;
}

export const Switch: ReactFCC<SwitchProps> = ({ defaultState = false, onChange }) => {
    const [value, setValue] = useState(defaultState);

    const onClick = () => {
        setValue(!value);
        onChange?.(!value);
    };

    return (
        <Container onClick={onClick} active={value}>
            <Ball active={value} />
        </Container>
    );
};
