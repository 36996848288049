import { questionnaireStores } from 'stores/builder/questionnaire';
import { TEMPLATES_LINK, DRAFTS_LINK } from 'constants/routes';
import { draftsEffects, draftsStores } from 'stores/drafts';
import { Button } from 'ui-kit/components/buttons/Button';
import { leaveDraftModalStore } from 'stores/modals';
import { designStores } from 'stores/builder/design';
import { blockStores } from 'stores/builder/block';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import { Buttons, Text } from './styles';

export const LeaveDraftModal: ReactFCC = () => {
    const { closeModal } = leaveDraftModalStore;
    const navigate = useNavigate();
    const draft = useUnit(draftsStores.draft);
    const blocks = useUnit(blockStores.blocks);
    const { questions } = useUnit(questionnaireStores.questionnaireItems);
    const coreConfig = useUnit(designStores.coverStyles);

    const deleteDraft = () => {
        if (draft) {
            const { id } = draft;
            draftsEffects.deleteDraft(id);
            navigate(TEMPLATES_LINK);
            closeModal();
        }
    };

    const saveDraft = () => {
        if (draft) {
            const {
                draft: { templateCategory, documentName, templateName, templateType },
                id
            } = draft;

            draftsEffects.updateDraft({
                templateConfig: {
                    detailsConfig: blocks.blocks,
                    questionnaire: questions,
                    coreConfig
                },
                templateCategory,
                documentName,
                templateName,
                templateType,
                id
            });
            navigate(DRAFTS_LINK);
            closeModal();
        }
    };

    return (
        <Modal onClose={closeModal} title="Leave Draft" visible>
            <Text>Do you want to save the Draft?</Text>
            <Buttons>
                <Button onClick={deleteDraft} variant="secondary">
                    Delete
                </Button>
                <Button onClick={saveDraft} variant="primary">
                    Save
                </Button>
            </Buttons>
        </Modal>
    );
};
