import { createEvent } from 'effector';

// * events
const addUpfrontSummaryEvent = createEvent<{
    // TODO: any
    block: any;
}>();
const addSectionToRowEvent = createEvent<{
    blockId: string;
    // TODO: any
    clause: any;
}>();

// * exports
export const upfrontSummaryEvents = {
    addUpfrontSummaryEvent,
    addSectionToRowEvent
};
