export enum CardCreationTabsEnum {
    NewCard = 'newCard',
    Cards = 'cards'
}

export enum AnimatedContractRadioButton {
    Purple = 'purple',
    Orange = 'orange',
    Green = 'green'
}

export enum TemplateTypesEnum {
    Secondary = 'secondaryTemplate',
    Primary = 'primaryTemplate'
}

export enum TemplateContractNamesEnum {
    RentalContract = 'rentalContract',
    RentalOffice = 'rentalOffice'
}

export enum ConstructorStepsEnum {
    Start = 'Start',
    Send = 'Send',
    Fill = 'Fill'
}

export enum TemplateFontKeysEnum {
    FontWeight = 'fontWeight',
    FontStyle = 'fontStyle'
}

export enum TemplateTextTypesEnum {
    Title = 'title',
    Body = 'body'
}

export enum TemplateTextTypesValues {
    Italic = 'italic',
    Normal = 'normal',
    Bold = 'bold'
}

export enum CompanyTemplateEnum {
    companyTemplate = 'company-template'
}

export enum RolesEnum {
    employees = 'employees',
    admin = 'company-admin',
    owner = 'company-owner'
}

export enum TemplateNameEnum {
    SecondTemplate = 'template-2',
    FirstTemplate = 'template-1'
}

export enum ConfigEnum {
    UnorderedList = 'UnorderedList',
    OrderedList = 'OrderedList',
    MultiSelect = 'MultiSelect',
    InputString = 'InputString',
    InputNumber = 'InputNumber',
    Datepicker = 'Date',
    Select = 'Select',
    Title = 'Title',
    Radio = 'Radio',
    Text = 'Text',
    File = 'File'
}

export enum ActiveColorKeyEnum {
    PrinterFriendlyColors = 'printerFriendlyColors',
    CurrentColors = 'currentColors'
}

export enum CoreConfigObjectKeysEnum {
    BodyText = 'bodyText',
    Headings = 'headings'
}

export enum HideOrSHowEnum {
    Hide = 'hide',
    Show = 'show'
}

export enum CSSPositionsEnum {
    Bottom = 'bottom',
    Right = 'right',
    Left = 'left',
    Top = 'top'
}

export enum SocialMediaEnum {
    Facebook = 'facebook',
    LinkedIn = 'linkedIn',
    Google = 'google'
}

export enum SubscriptionStatusEnum {
    Canceled = 'canceled',
    Active = 'active'
}

export enum AccountPageTabNameEnum {
    PersonalAndCompany = 'Personal & Company',
    Admin = 'Admin & Users',
    Security = 'Security',
    Profile = 'Profile',
    Payment = 'Payment',
    Links = 'Links'
}

export enum CompanyTabNameEnum {
    Profile = 'Personal & Company'
}

export enum ContractTypeEnum {
    Privacy = 'AVG/PRIVACY',
    Letters = 'Letters',
    Hiring = 'Hiring'
}

export enum Rates {
    Enterprise = 'enterprise',
    free = 'free',
    Pro = 'pro'
}
