import type { Margin, Sizes } from 'types/styles';

import { xl } from 'ui-kit/GlobalStyle/screansSizes';
import styled from 'styled-components';

interface Gap {
    gap?: string;
}

interface Props extends Pick<Sizes, 'width'>, Gap {
    alignItems?: string;
    quantity?: number;
}
export const Grid = styled.section<Props>`
    display: grid;
    grid-gap: ${({ gap }) => gap || '30px'};
    grid-template-columns: repeat(${({ quantity }) => quantity || 4}, 1fr);
    width: ${({ width }) => width || '100%'};

    @media (max-width: 1400px) {
        grid-template-columns: repeat(${({ quantity }) => quantity || 3}, 1fr);
    }
`;

interface AdaptiveGridProps extends Sizes, Gap, Pick<Margin, 'marginBottom' | 'marginRight' | 'marginTop'> {
    gridRowsGap?: string;
    alignItems?: string;
    xxl: string;
    xl?: string;
}
export const AdaptiveGrid = styled.section<AdaptiveGridProps>`
    display: grid;
    grid-gap: ${({ gap }) => gap || '20px'};
    ${({ gridRowsGap }) => gridRowsGap && `grid-row-gap: ${gridRowsGap}`};
    grid-template-columns: ${({ xxl }) => xxl};
    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
    width: ${({ width }) => width || '100%'};
    ${({ height }) => height && `height: ${height}`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};

    @media (max-width: ${xl}) {
        ${({ xl }) => xl && `grid-template-columns: ${xl}`};
    }
`;
