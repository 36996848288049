import {
    inputTextHorizontalPadding,
    inputTextVerticalPadding,
    inputHeight
} from 'components/common/inputs/StyledInput/constants';
import { disableDefaultInputStyleMixin, flexCenter } from 'constants/styles/mixins';
import { PURPLE_80, GRAY_60, DANGER } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';
import media from 'utils/media-query';

export interface StyledInputProps {
    disabled?: boolean;
    touched?: boolean;
    error?: string;
    value?: string;
}

export const styledInputMixin = css<StyledInputProps>`
    ${disableDefaultInputStyleMixin};
    width: 100%;
    height: ${inputHeight};
    border-radius: 10px;
    border: 1px solid ${GRAY_60};
    padding: ${inputTextVerticalPadding} ${inputTextHorizontalPadding};

    :focus {
        border-color: ${({ error }) => (error ? DANGER : PURPLE_80)};
    }

    &::placeholder {
        color: #cdd0d9;
    }

    ${({ touched, error }) => touched && error && `border-color: ${DANGER}`};
`;

export const textInputMixin = css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    ${media.tabletSmall`
        font-size: 12px;
    `}
`;

export const StyledInputWrapper = styled.input<StyledInputProps>`
    ${styledInputMixin};
    ${textInputMixin};
    ${({ disabled }) => disabled && 'pointer-events: none'};
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const InformationInputWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 16px;
    ${flexCenter};
    height: 100%;
`;
