import type { ReactFCC } from 'types/react';

import { RichComponent } from 'components/builder/RichComponent/RichComponent';
import { Modal } from 'ui-kit/components/Modal';
import { logicModalStore } from 'stores/modals';
import { useUnit } from 'effector-react';

export const LogicModal: ReactFCC = () => {
    const { closeModal, modal } = logicModalStore;
    const [
        _,
        {
            closeQuestionnaireModal,
            modalTitle = 'Logic',
            variationsBlock,
            variationsField,
            selectedField,
            blockTitle,
            clauseId,
            blockId,
            textId,
            clause
        }
    ] = useUnit(modal);

    const handleOnClose = () => {
        closeModal();
        closeQuestionnaireModal?.();
    };

    return (
        <Modal onClose={handleOnClose} title={modalTitle} visible>
            <RichComponent
                variationsBlock={variationsBlock}
                variationsField={variationsField}
                selectedField={selectedField}
                blockTitle={blockTitle}
                onClose={handleOnClose}
                clauseId={clauseId}
                blockId={blockId}
                clause={clause}
                textId={textId}
            />
        </Modal>
    );
};
