import type { BlockInterface, Fields } from 'types/builder/interface';

import { createEvent } from 'effector';

// * events
const addSigningBlockEvent = createEvent<{
    block: BlockInterface;
}>();
const addRowEvent = createEvent<{
    component: string;
    blockId: string;
    clause: Fields;
}>();
const addSectionToRowEvent = createEvent<{
    blockId: string;
    clause: Fields;
}>();
const reorderFieldsInGroup = createEvent<{
    groupId: string;
    items: Fields[];
}>();
const deleteSectionEvent = createEvent<{
    blockId: string;
    fieldId: string;
}>();

// * exports
export const signingBlockEvents = {
    addSigningBlockEvent,
    addSectionToRowEvent,
    reorderFieldsInGroup,
    deleteSectionEvent,
    addRowEvent
};
