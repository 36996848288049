import { GRAY_100, GRAY_60 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: ${GRAY_100};
    margin-bottom: 16px;
`;

export const Span = styled.span``;

export const SelectContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    & > span {
        font-weight: 400;
        font-size: 14px;
        color: ${GRAY_100};
        display: inline-block;
        margin-bottom: 8px;
    }
`;

export const Or = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;

    & hr {
        width: 50%;
        margin: 0;
        border-color: ${GRAY_60};
        border-bottom: none;
        border-bottom-color: ${GRAY_60};
    }

    & span {
        padding: 0 16px;
        white-space: nowrap;
    }
`;

export const Hr = styled.hr``;
