import { type InputHTMLAttributes, type RefObject, useEffect, useState, useMemo } from 'react';
import { DescriptionBadge } from 'components/common/DescriptionBadge';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { type ReactFCC } from 'types/react';
import { useToggle } from 'react-use';

import { ShowPasswordIconContainer, StyledInput, Container, Content } from './styles';
import { EyeIconClose, EyeIconOpen } from './static_assets';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    reference?: React.RefObject<HTMLInputElement> | null;
    refik?: RefObject<any>;
    isFormInput?: boolean;
    description?: string;
    isEllipsis?: boolean;
    titleColor?: string;
    isTouched?: boolean;
    value?: string;
    error?: string;
}

export const Input: ReactFCC<InputProps> = ({
    autoComplete,
    isFormInput,
    description,
    titleColor,
    isEllipsis,
    isTouched,
    reference,
    disabled,
    error,
    title,
    refik,
    type,
    ...inputProps
}: InputProps) => {
    const [inputType, setInputType] = useState(type);
    const [showPassword, setShowPassword] = useToggle(false);
    const isPasswordType = useMemo(() => type === 'password', [type]);

    const onChangePasswordView = () => {
        setInputType(showPassword ? 'password' : 'text');
        setShowPassword();
    };

    useEffect(() => setInputType(type), [type]);

    return (
        <Container>
            {(error || title) && (
                <InputTitle color={titleColor} isError={!!error}>
                    {error || title || ''}
                    {description && <DescriptionBadge description={description} />}
                </InputTitle>
            )}
            <Content>
                <StyledInput
                    ref={isFormInput ? refik : reference}
                    autoComplete={autoComplete}
                    isEllipsis={isEllipsis}
                    disabled={disabled}
                    touched={isTouched}
                    type={inputType}
                    error={error}
                    {...inputProps}
                />
                {isPasswordType && (
                    <ShowPasswordIconContainer onClick={onChangePasswordView} role="button">
                        {showPassword ? <EyeIconOpen /> : <EyeIconClose />}
                    </ShowPasswordIconContainer>
                )}
            </Content>
        </Container>
    );
};
