import { PURPLE_100, PURPLE_60, WHITE } from 'ui-kit/GlobalStyle/colors';
import { APP_FONT_FAMILY } from 'ui-kit/GlobalStyle/appFontFamily';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledAside = styled.aside`
    width: 250px;
    height: 100vh;
    background-color: ${WHITE};
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f3f3f3;
`;

export const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
    box-sizing: border-box;
    width: 100%;
    padding: 12px 24px;
    color: ${PURPLE_100};
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 12px;

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const Avatar = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${PURPLE_60};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AvatarInfo = styled.span`
    color: ${WHITE};
    font-family: ${APP_FONT_FAMILY};
    font-weight: 500;
    font-size: 14px;
`;

export const LogoutText = styled.span`
    font-size: 16px;
    color: #7d828e;
`;

export const LogoutButton = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
`;

export const LogoContainer = styled.div`
    padding: 20px;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 24px;
`;

export const UserInfo = styled.div`
    padding: 16px 24px;
    font-weight: 600;
    font-size: 16px;
    background-color: #fbfbfb;
    color: #160135;
    display: flex;
    gap: 8px;
`;

export const Content = styled.div`
    flex: 1;

    & .active {
        color: ${PURPLE_60};
        background-color: #f9f5ff;
        font-weight: 600;
        border-left: 6px solid ${PURPLE_60};
        padding-left: 18px;
    }
`;
