import { ORANGE } from 'ui-kit/GlobalStyle/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface Color {
    $color?: string;
}

export const InternalLink = styled(NavLink)<Color>`
    color: ${({ $color }) => $color || ORANGE};
`;
