import type { ReactFCC } from 'types/react';
import type { EditorState } from 'draft-js';

import { isStyleApplied } from '../../helpers/is-style-applied';
import { StrikethroughIcon } from './static_assets';
import { Button } from './styles';

interface Props {
    editorState: EditorState;
    onClick: () => void;
}

export const StrikethroughButton: ReactFCC<Props> = ({ editorState, onClick }) => (
    <Button $isActive={isStyleApplied('STRIKETHROUGH', editorState)} onClick={onClick}>
        <StrikethroughIcon />
    </Button>
);
