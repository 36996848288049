import type { JoinUserData } from 'pages/JoinToCompany/types';

import axios from 'api/axios';

export const getCompany = (id: string) =>
    axios<API.Company>({
        url: `/companies/${id}`,
        method: 'GET'
    });

export const inviteUser = (data: API.InviteDto) =>
    axios({
        url: 'companies/invite',
        data
    });

export const joinUser = (data: JoinUserData, companyId: string) =>
    axios<API.SuccessAnswer>({
        url: `companies/${companyId}/add-user`,
        method: 'PATCH',
        data
    });

export const addCompany = (data: API.RegisterCompanyDto) =>
    axios({
        url: 'companies',
        data
    });

export const getCompanyUsers = (query: string) =>
    // TODO: update any when API.GetManyResponseForUser is updated
    axios<any>({
        url: `users?${query}`,
        method: 'GET'
    });

export const deleteCompanyUser = (userId: string) =>
    axios<API.SuccessAnswer>({
        url: `users/delete-employee/${userId}`,
        method: 'DELETE'
    });

export const updateCompanyInfo = (data: API.UpdateCompanyDto, companyId: string) =>
    axios<API.SuccessAnswer>({
        url: `companies/${companyId}/update`,
        method: 'PATCH',
        data
    });
