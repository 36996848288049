export const PURPLE_100 = '#160135';
export const PURPLE_80 = '#6d7cff';
export const PURPLE_60 = '#0014cb';
export const PURPLE_40 = '#e9f4ff';
export const PURPLE_20 = '#e9f4ff';
export const GRAY_100 = '#2a4660';
export const GRAY_80 = '#59758f';
export const GRAY_60 = '#bbd6ef';
export const GRAY_40 = '#e9ebee';
export const GRAY_20 = '#e9f4ff';
export const ORANGE = '#ffb1b1';
export const DANGER = '#ff4b61';
export const BLUE = '#fff06b';
export const WHITE = '#ffffff';
export const SUCCESS = '#83e2b5';
export const YELLOW = '#FFF06B';
