import { StyledTextInput } from 'components/common/inputs/StyledInput';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { Button } from 'ui-kit/components/buttons/Button';
import { addPaymentCardModalStore } from 'stores/modals';
import { Modal } from 'ui-kit/components/Modal';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import { Container } from './styles';

export const AddPaymentCardModal: ReactFCC = () => {
    const { closeModal, modal } = addPaymentCardModalStore;
    const [_, { title }] = useUnit(modal);

    const handleClose = () => {
        closeModal();
    };

    return (
        <Modal onClose={handleClose} title={title} visible>
            <Container>
                <StyledTextInput placeholder="Cardholder’s name *" title="Cardholder’s name *" />
                <StyledTextInput placeholder="Card number *" title="Card number *" />
                <StyledTextInput placeholder="Expiration date *" title="Expiration date *" />
                <StyledTextInput placeholder="CVV *" title="CVV *" />
                <Row justifyEnd gap="16px">
                    <Button variant="primary">Save</Button>
                </Row>
            </Container>
        </Modal>
    );
};
