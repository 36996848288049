import { CommonErrorModal } from 'components/modals/CommonErrorModal/CommonErrorModal';
import { GlobalStyle } from 'ui-kit/GlobalStyle/GlobalStyle';
import { type PropsWithChildren, Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18n';

export class ErrorBoundary extends Component<PropsWithChildren> {
    state = {
        hasError: false
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <I18nextProvider i18n={i18n}>
                    <GlobalStyle />
                    <CommonErrorModal />
                </I18nextProvider>
            );
        }

        return (
            <I18nextProvider i18n={i18n}>
                <GlobalStyle />
                {children}
            </I18nextProvider>
        );
    }
}
