import { setIsLoadingTemplatePagesEvent, setMountingEvent } from 'stores/templatePages/model';
import { ownTemplatesEffects } from 'stores/ownTemplates';
import { draftsEffects } from 'stores/drafts';
import { sample } from 'effector';

import { deleteClauseVariationsHelper } from './helpers/delete-clause-variations-helper';
import { deleteBlockVariationsHelper } from './helpers/delete-block-variations-helper';
import { addClauseVariationsHelper } from './helpers/add-clause-variations-helper';
import { deleteClauseVariantHelper } from './helpers/delete-clause-variant-helper';
import { addBlockVariationsHelper } from './helpers/add-block-variations-helper';
import { deleteClauseHelper } from './helpers/delete-clause-helper';
import { addParagraphHelper } from './helpers/add-paragraph-helper';
import { questionnaireEvents, questionnaireStores } from './index';
import { signingBlockEvents } from '../signingBlock';
import { updateFieldEvent } from '../field/model';
import { clauseEvents } from '../clause';
import { designEvents } from '../design';
import { blockEvents } from '../block';

questionnaireStores.questionnaireItems.on(updateFieldEvent, (state, { newField, fieldId }) => ({
    ...state,
    questions: state.questions.map(question =>
        question.fieldId === fieldId
            ? {
                  ...question,
                  description: newField.description,
                  subtitle: newField.question
              }
            : question
    )
}));

// * block
questionnaireStores.questionnaireItems
    .on(blockEvents.deleteBlockEvent, (state, { blockId }) => ({
        ...state,
        questions: state.questions.filter(question => question.blockId !== blockId)
    }))
    .on(blockEvents.addBlockVariationsEvent, (state, { blockId, data }) => ({
        ...state,
        questions: addBlockVariationsHelper(state.questions, blockId, data)
    }))
    .on(blockEvents.deleteBlockVariationsEvent, (state, { blockId }) => ({
        ...state,
        questions: deleteBlockVariationsHelper(state.questions, blockId)
    }))
    .on(blockEvents.addClauseVariationsEvent, (state, { clauseId, blockId, data }) => ({
        ...state,
        questions: addClauseVariationsHelper(state.questions, blockId, clauseId, data)
    }))
    .on(blockEvents.deleteClauseVariationsEvent, (state, { clauseId }) => ({
        ...state,
        questions: deleteClauseVariationsHelper(state.questions, clauseId)
    }))
    .on(blockEvents.addParagraphEvent, (state, { paragraphs, contentId, clauseId, blockId, isShown }) => ({
        ...state,
        questions: addParagraphHelper(state.questions, paragraphs, clauseId, blockId, contentId, isShown)
    }));

// * signing block
questionnaireStores.questionnaireItems.on(signingBlockEvents.deleteSectionEvent, (state, { fieldId }) => ({
    ...state,
    questions: deleteClauseHelper(state.questions, fieldId)
}));

// * clause
questionnaireStores.questionnaireItems
    .on(clauseEvents.deleteClauseEvent, (state, { fieldId }) => ({
        ...state,
        questions: deleteClauseHelper(state.questions, fieldId)
    }))
    .on(clauseEvents.deleteVariant, (state, { contentId }) => ({
        ...state,
        questions: deleteClauseVariantHelper(state.questions, contentId)
    }));

// * effects
questionnaireStores.questionnaireItems
    .on(ownTemplatesEffects.getTemplate.doneData, (_, { templateConfig: { questionnaire } }) => ({
        questions: questionnaire
    }))
    .on(
        draftsEffects.getDraft.doneData,
        (
            _,
            {
                draft: {
                    templateConfig: { questionnaire }
                }
            }
        ) => ({
            questions: questionnaire
        })
    );

sample({
    clock: [
        questionnaireEvents.addQuestionnaireValues,
        blockEvents.changeShowBlockEvent,
        blockEvents.changeShowClauseEvent,
        designEvents.changeActiveDesign,
        designEvents.changePalletIndex,
        designEvents.changeDocumentFonts
    ],
    fn: () => {
        setTimeout(() => {
            setIsLoadingTemplatePagesEvent(false);
            setMountingEvent(false);
        }, 1000);
    }
});
