import type { MultiValue } from 'react-select';

import { createEvent, createStore } from 'effector';

import type { Payload } from './types';

// * events
const initializeShowOrHide = createEvent<Payload[]>();
const initializeClauseOrBlock = createEvent<Payload[]>();
const initializeMultiSelectValues =
    createEvent<Array<{ value: MultiValue<{ value: string; label: string }>; key: string }>>();
const setShowOrHide = createEvent<Payload>();
const setMultiSelectValues = createEvent<{
    value: MultiValue<{ value: string; label: string }>;
    key: string;
}>();
const resetShowOrHide = createEvent();
const resetFieldOrClauseOrBlock = createEvent();
const resetMultiSelectValues = createEvent();

// * stores
const showOrHide = createStore<Payload[] | []>([])
    .on(initializeShowOrHide, (_, payload) => payload)
    .on(setShowOrHide, (prevState, payload) =>
        prevState.map(item =>
            payload.key === item.key
                ? {
                      ...item,
                      value: payload.value
                  }
                : item
        )
    )
    .reset(resetShowOrHide);

const multiSelectValues = createStore<Array<{ value: MultiValue<{ value: string; label: string }>; key: string }> | []>(
    []
)
    .on(initializeMultiSelectValues, (_, payload) => payload)
    .on(setMultiSelectValues, (prevState, payload) =>
        prevState.map(item =>
            payload.key === item.key
                ? {
                      ...item,
                      value: payload.value
                  }
                : item
        )
    )
    .reset(resetMultiSelectValues);

// * exports
export const hideOrShowEvents = {
    initializeMultiSelectValues,
    resetFieldOrClauseOrBlock,
    initializeClauseOrBlock,
    resetMultiSelectValues,
    setMultiSelectValues,
    initializeShowOrHide,
    resetShowOrHide,
    setShowOrHide
};

export const hideOrShowStores = {
    multiSelectValues,
    showOrHide
};
