import axios from 'api/axios';

export const createContract = (data: FormData) =>
    axios<{ id: string }>({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: '/file',
        data
    });

export const getContracts = (query: string) =>
    axios<API.GetManyResponseForContract>({
        url: `contracts?${query}`,
        method: 'GET'
    });

export const deleteContract = (id: string) =>
    axios({
        url: `contracts/${id}`,
        method: 'DELETE'
    });
