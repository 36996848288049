import { type CSSObject, css } from 'styled-components';

export const sizes = {
    tabletSmall: 1023,
    desktopLg: 1919,
    desktop: 1439,
    tablet: 1279,
    phone: 767
};

type KeysType = keyof typeof sizes;
type MediaFunction = (first: TemplateStringsArray | CSSObject, ...interpolations: any[]) => any;

export default (Object.keys(sizes) as KeysType[]).reduce(
    (acc, label) => {
        acc[label] = (first, ...interpolations) => css`
            @media (max-width: ${sizes[label]}px) {
                ${css(first, ...interpolations)}
            }
        `;

        return acc;
    },
    {} as { [key in KeysType]: MediaFunction }
);
