import type { BlockInterface, Fields } from 'types/builder/interface';

import { v4 as generateId } from 'uuid';

export const addSectionToRowHelper = (
    groups: BlockInterface['groups'],
    // TODO: any
    clause: any,
    currentFields: {
        id?: undefined | string;
        fields: Fields[];
    }
) => {
    if (currentFields.fields.length === 2 && !currentFields.fields.some(item => item.type === 'EMPTY')) {
        return [
            ...groups,
            {
                fields: [
                    { ...clause, title: '' },
                    { id: generateId(), type: 'EMPTY' }
                ],
                id: generateId()
            }
        ];
    }

    if (currentFields.fields.some(item => item.type === 'EMPTY') && currentFields.fields.length === 2) {
        return groups.map((item, index) => {
            if (index === groups.length - 1) {
                const newFields = item.fields.filter(item => item.type !== 'EMPTY');

                return {
                    ...item,
                    fields: [
                        ...newFields,
                        {
                            ...clause,
                            title: ''
                        }
                    ]
                };
            }

            return item;
        });
    }

    if (groups.length === 0) {
        return [
            {
                fields: [
                    {
                        ...clause,
                        title: ''
                    },
                    { id: generateId(), type: 'EMPTY' }
                ],
                id: generateId()
            }
        ];
    }

    return groups.map((item, index) =>
        index === groups.length - 1
            ? {
                  ...item,
                  fields: [
                      ...item.fields,
                      {
                          ...clause,
                          title: ''
                      },
                      { id: generateId(), type: 'EMPTY' }
                  ]
              }
            : item
    );
};
