import {
    checkEmailForExistenceFx,
    updateUserPasswordFx,
    recoverPasswordFx,
    changePasswordFx,
    changeUserRoleFx,
    verifyAccountFx,
    createUserFx,
    updateUserFx,
    loadTokenFx,
    getMeFx
} from 'stores/user/model';
import { copyTemplatesEffects } from 'stores/copyTemplates';
import { ownTemplatesEffects } from 'stores/ownTemplates';
import { documentsEffects } from 'stores/documents';
import { sendQuestionFx } from 'stores/feedback';
import { companyEffects } from 'stores/company';
import { draftsEffects } from 'stores/drafts';
import { roleEffects } from 'stores/role';
import { pdfEffects } from 'stores/pdf';
import { pending } from 'patronum';

// * stores
export const $isLoading = pending([
    ...Object.values({
        ...companyEffects,
        ...pdfEffects,
        ...roleEffects,
        ...ownTemplatesEffects,
        ...copyTemplatesEffects,
        ...documentsEffects
    }),
    draftsEffects.createDraft,
    draftsEffects.deleteDraft,
    draftsEffects.getDraft,
    draftsEffects.getDrafts,
    sendQuestionFx,
    loadTokenFx,
    verifyAccountFx,
    createUserFx,
    checkEmailForExistenceFx,
    recoverPasswordFx,
    updateUserFx,
    updateUserPasswordFx,
    getMeFx,
    changeUserRoleFx,
    changePasswordFx
]);
