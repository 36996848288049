import type { ReactFCC } from 'types/react';

import { useState, useRef } from 'react';
import { usePopper } from 'react-popper';

import { Popper, Target, Arrow } from './styles';

export type Placement = 'bottom' | 'right' | 'left' | 'top';

export interface TooltipProps {
    placement?: Placement;
    isActivated: boolean;
    content?: string;
}

export const Tooltip: ReactFCC<TooltipProps> = ({ placement = 'top', isActivated, children, content }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const { attributes, styles } = usePopper(referenceElement, popperElement, {
        modifiers: [{ options: { element: arrowElement }, name: 'arrow' }],
        placement
    });

    const onMouseEnter = () => {
        setTooltipVisible(true);
    };

    const onMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <>
            <Target
                onMouseEnter={isActivated ? onMouseEnter : undefined}
                onMouseLeave={isActivated ? onMouseLeave : undefined}
                ref={setReferenceElement}
            >
                <div ref={ref}>{children}</div>
            </Target>
            {isTooltipVisible && (
                <Popper
                    style={{
                        ...styles.popper
                    }}
                    ref={setPopperElement}
                    placement={placement}
                    {...attributes.popper}
                >
                    {content}
                    <Arrow ref={setArrowElement} placement={placement} style={styles.arrow} />
                </Popper>
            )}
        </>
    );
};
