import { GRAY_60, ORANGE, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

interface Active {
    active?: boolean;
}

export const Container = styled.div<Active>`
    position: relative;
    width: 50px;
    height: 25px;
    border-radius: calc(25px / 2);
    background-color: ${({ active }) => (active ? ORANGE : GRAY_60)};
    cursor: pointer;
    transition: 0.2s;
`;

export const Ball = styled.div<Active>`
    position: absolute;
    top: calc((25px - 19px) / 2);
    left: ${({ active }) => (active ? `calc(50px - 19px - calc((25px - 19px) / 2))` : 'calc((25px - 19px) / 2)')};
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: ${WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
`;
