import { GRAY_80 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BodyContainer = styled.div`
    padding-bottom: 20px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Text = styled.p`
    font-size: 16px;
    line-height: 20px;
    color: ${GRAY_80};
    margin-top: 10px;
`;
