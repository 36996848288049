import { createEffect } from 'effector';
import { API } from 'api';

// * effects
const copyTemplate = createEffect({
    handler: async (data: KIARA.CopyTemplateRequest) => await API.copyTemplates.copyTemplate(data)
});

// * exports
export const copyTemplatesEffects = {
    copyTemplate
};
