import { type MouseEvent, type ReactNode, useCallback, useEffect } from 'react';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { type ReactFCC } from 'types/react';
import ReactModal from 'react-modal';

import { ModalFooter, ModalHeader, ModalBody, Section, Title } from './styles';
import closeIcon from './static_assets/close.svg';
import { content, overlay } from './constants';

interface Props {
    disabledCloseIcon?: boolean;
    customHeader?: ReactNode;
    customFooter?: ReactNode;
    onClose?: () => void;
    visible: boolean;
    height?: string;
    title?: string;
    width?: string;
}

export const Modal: ReactFCC<Props> = ({
    customHeader,
    customFooter,
    height = '',
    title = '',
    width = '',
    children,
    visible,
    onClose
}) => {
    const handleClose = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            onClose?.();
        },
        [onClose]
    );

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <ReactModal
            style={{
                content: { ...content, height: height, width: width },
                overlay: { ...overlay }
            }}
            ariaHideApp={false}
            isOpen={visible}
        >
            <ModalHeader $hasBorder={customHeader !== undefined || title !== undefined}>
                <Section>
                    {customHeader || <Title dangerouslySetInnerHTML={{ __html: title }} />}
                    {onClose && <CustomImage onClick={handleClose} diameter="10px" src={closeIcon} pointer />}
                </Section>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            {customFooter && <ModalFooter $hasBorder={customFooter !== undefined}>{customFooter}</ModalFooter>}
        </ReactModal>
    );
};
