import { ownTemplatesEffects } from 'stores/ownTemplates';
import { draftsEffects } from 'stores/drafts';
import { v4 as generateId } from 'uuid';

import { addUpfrontSummarySectionToRowHelper } from './helpers/add-upfront-summary-section-to-row-helper';
import { addTimeLineSectionToRowHelper } from './helpers/add-timeline-section-to-row-helper';
import { addSigningBlockRowReducer } from './reducers/add-signing-block-row-reducer';
import { addSectionToRowHelper } from './helpers/add-section-to-row-helper';
import { makeIsDefaultHelper } from './helpers/make-is-default-helper';
import { upfrontSummaryEvents } from '../upfrontSummary';
import { signingBlockEvents } from '../signingBlock';
import { coverElementEvents } from '../coverElement';
import { updateFieldEvent } from '../field/model';
import { timeLineEvents } from '../timeLine';
import { clauseEvents } from '../clause';
import { blockStores } from './index';

// * cover element
blockStores.blocks
    .on(coverElementEvents.toggleShowParties, state => ({
        ...state,
        blocks: state.blocks.map(item =>
            item.component === 'COVER'
                ? {
                      ...item,
                      groups: item.groups.map(item => ({
                          ...item,
                          fields: item.fields.map(item => ({
                              ...item,
                              content: item.content.map((item, index) =>
                                  index === 0 || index === 1 ? { ...item, show: !item.show } : item
                              )
                          }))
                      }))
                  }
                : item
        )
    }))
    .on(coverElementEvents.toggleShowDate, state => ({
        ...state,
        blocks: state.blocks.map(item =>
            item.component === 'COVER'
                ? {
                      ...item,
                      groups: item.groups.map(item => ({
                          ...item,
                          fields: item.fields.map(item => ({
                              ...item,
                              content: item.content.map((item, index) =>
                                  index === 2 ? { ...item, show: !item.show } : item
                              )
                          }))
                      }))
                  }
                : item
        )
    }))
    .on(coverElementEvents.toggleShowLogo, state => ({
        ...state,
        blocks: state.blocks.map(item =>
            item.component === 'COVER'
                ? {
                      ...item,
                      groups: item.groups.map(item => ({
                          ...item,
                          fields: item.fields.map(item => ({
                              ...item,
                              content: item.content.map((item, index) =>
                                  index === 3 ? { ...item, show: !item.show } : item
                              )
                          }))
                      }))
                  }
                : item
        )
    }))
    .on(coverElementEvents.onChangeFields, (state, { index, value }) => ({
        ...state,
        blocks: state.blocks.map(item =>
            item.component === 'COVER'
                ? {
                      ...item,
                      groups: item.groups.map(item => ({
                          ...item,
                          fields: item.fields.map(item => ({
                              ...item,
                              content: item.content.map((item, partyIndex) =>
                                  partyIndex === index
                                      ? { ...item, paragraph: item.paragraph.map(item => ({ ...item, value })) }
                                      : item
                              )
                          }))
                      }))
                  }
                : item
        )
    }));

// * signing block
// @ts-ignore
// TODO: ts-ignore
blockStores.blocks
    .on(signingBlockEvents.addSigningBlockEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block], documentName: '' }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(signingBlockEvents.reorderFieldsInGroup, (state, { groupId, items }) => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            groups: block.groups.map(item => (item.id === groupId ? { ...item, fields: items } : item))
        }))
    }))
    .on(signingBlockEvents.deleteSectionEvent, (state, { blockId, fieldId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: block.groups
                          .map(item => ({
                              ...item,
                              fields: item.fields.map((field, index) => {
                                  const itemIndexForDeleting = item.fields.findIndex(field => field.id === fieldId);

                                  if (index === itemIndexForDeleting) {
                                      return {
                                          id: generateId(),
                                          type: 'EMPTY'
                                      };
                                  }
                                  return field;
                              })
                          }))
                          .filter(item => !item.fields.every(field => field.type === 'EMPTY'))
                  }
                : block
        )
    }))
    .on(signingBlockEvents.addSectionToRowEvent, (state, { blockId, clause }) => {
        const groups = state?.blocks.find(item => item.id === blockId)?.groups;
        const currentFields = groups?.[groups?.length - 1] || { fields: [] };

        return {
            ...state,
            blocks: state.blocks.map(block =>
                block.id === blockId
                    ? {
                          ...block,
                          groups: addSectionToRowHelper(block.groups, clause, currentFields)
                      }
                    : block
            )
        };
    })
    .on(signingBlockEvents.addRowEvent, addSigningBlockRowReducer);

// * upfront summary
blockStores.blocks
    .on(upfrontSummaryEvents.addUpfrontSummaryEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block], documentName: '' }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(upfrontSummaryEvents.addSectionToRowEvent, (state, { blockId, clause }) => {
        const groups = state?.blocks.find(item => item.id === blockId)?.groups;
        const currentFields = groups?.[groups?.length - 1] || { fields: [] };

        return {
            ...state,
            blocks: state.blocks.map(block =>
                block.id === blockId
                    ? {
                          ...block,
                          groups: addUpfrontSummarySectionToRowHelper(block.groups, clause, currentFields)
                      }
                    : block
            )
        };
    });

// * timeline
blockStores.blocks
    .on(timeLineEvents.addTimeLineEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block], documentName: '' }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(timeLineEvents.addSectionToRowEvent, (state, { blockId, clause }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: addTimeLineSectionToRowHelper(block.groups, clause)
                  }
                : block
        )
    }));

// * field
blockStores.blocks.on(updateFieldEvent, (state, { contentId, clauseId, newField, blockId, fieldId }) => ({
    ...state,
    blocks: state.blocks.map(block =>
        block.id === blockId
            ? {
                  ...block,
                  groups: block.groups.map(item => ({
                      ...item,
                      fields: item.fields.map(field =>
                          field.id === clauseId
                              ? {
                                    ...field,
                                    content: field.content.map(item =>
                                        item.id === contentId
                                            ? {
                                                  ...item,
                                                  paragraph: item.paragraph.map(item =>
                                                      item.fieldId === fieldId ? { ...item, ...newField } : item
                                                  )
                                              }
                                            : item
                                    )
                                }
                              : field
                      )
                  }))
              }
            : block
    )
}));

// * clause
blockStores.blocks
    .on(clauseEvents.makeIsDefaultEvent, (state, { contentId, clauseId, blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: [
                          {
                              fields: block.groups[0].fields.map(field =>
                                  field.id === clauseId
                                      ? {
                                            ...field,
                                            content: makeIsDefaultHelper(field.content, contentId)
                                        }
                                      : field
                              )
                          }
                      ]
                  }
                : block
        )
    }))
    .on(clauseEvents.deleteVariant, (state, { contentId, clauseId, blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: [
                          {
                              fields: block.groups[0].fields.map(field =>
                                  field.id === clauseId
                                      ? {
                                            ...field,
                                            content: field.content.filter(item => item.id !== contentId)
                                        }
                                      : field
                              )
                          }
                      ]
                  }
                : block
        )
    }))
    .on(clauseEvents.addVariant, (state, { clauseId, blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: [
                          {
                              fields: block.groups[0].fields.map(field =>
                                  field.id === clauseId
                                      ? {
                                            ...field,
                                            content: [
                                                ...field.content,
                                                {
                                                    isDefault: false,
                                                    id: generateId(),
                                                    paragraph: [],
                                                    show: false
                                                }
                                            ]
                                        }
                                      : field
                              )
                          }
                      ]
                  }
                : block
        )
    }))
    .on(clauseEvents.toggleNumberingEvent, state => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            groups: [
                {
                    fields: block.groups[0].fields.map(field => ({
                        ...field,
                        withNumbering: !field.withNumbering
                    }))
                }
            ]
        }))
    }))
    .on(clauseEvents.deleteClauseEvent, (state, { blockId, fieldId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: block.groups.map(item => ({
                          ...item,
                          fields: item.fields.filter(field => field.id !== fieldId)
                      }))
                  }
                : block
        )
    }))
    .on(clauseEvents.addClauseEvent, (state, { blockId, clause }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: [
                          {
                              fields: [
                                  ...block.groups[0].fields,
                                  {
                                      ...clause,
                                      title: ''
                                  }
                              ]
                          }
                      ]
                  }
                : block
        )
    }))
    .on(clauseEvents.changeClauseTitleEvent, (state, { blockId, fieldId, title }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId
                ? {
                      ...block,
                      groups: block.groups.map(item => ({
                          ...item,
                          fields: item.fields.map(field =>
                              field.id === fieldId
                                  ? {
                                        ...field,
                                        title
                                    }
                                  : field
                          )
                      }))
                  }
                : block
        )
    }));

// * effects
blockStores.blocks
    .on(ownTemplatesEffects.getTemplate.doneData, (_, { templateConfig: { detailsConfig }, documentName }) => ({
        blocks: detailsConfig,
        isChanged: false,
        documentName
    }))
    .on(
        draftsEffects.getDraft.doneData,
        (
            _,
            {
                draft: {
                    templateConfig: { detailsConfig },
                    documentName
                }
            }
        ) => ({
            blocks: detailsConfig,
            isChanged: false,
            documentName
        })
    );
