import * as copyTemplates from 'api/services/copyTemplates';
import * as ownTemplates from 'api/services/ownTemplates';
import * as contracts from 'api/services/contracts';
import * as templates from 'api/services/templates';
import * as documents from 'api/services/documents';
import * as feedback from 'api/services/feedback';
import * as company from 'api/services/company';
import * as draft from 'api/services/draft';
import * as cover from 'api/services/cover';
import * as role from 'api/services/role';
import * as user from 'api/services/user';

export const API = {
    copyTemplates,
    ownTemplates,
    contracts,
    templates,
    documents,
    feedback,
    company,
    draft,
    cover,
    user,
    role
};
