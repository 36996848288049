import { ownTemplatesEffects, ownTemplatesStores } from 'stores/ownTemplates';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { Button } from 'ui-kit/components/buttons/Button';
import { Input } from 'ui-kit/components/inputs/Input';
import { templateModalStore } from 'stores/modals';
import { Modal } from 'ui-kit/components/Modal';
import { type ReactFCC } from 'types/react';
import { useQuery } from 'hooks/useQuery';
import { useUnit } from 'effector-react';

export const TemplateModal: ReactFCC = () => {
    const { closeModal, modal } = templateModalStore;
    const [_, { documentName, config }] = useUnit(modal);
    const ownTemplate = useUnit(ownTemplatesStores.template);
    const [templateName, setTemplateName] = useState('');
    const location = useQuery<{ ownTemplate?: string }>();
    const isUpdatingTemplate = !!location?.ownTemplate;

    const createConfig = useCallback(async () => {
        const data = {
            templateCategory: 'Hiring',
            templateType: 'Contract',
            templateConfig: config,
            documentName,
            templateName
        };

        if (location?.ownTemplate) {
            return ownTemplatesEffects.updateTemplate({ id: location.ownTemplate, data });
        }

        return ownTemplatesEffects.createTemplate(data);
    }, [config, documentName, templateName, location]);

    useEffect(() => {
        if (ownTemplate) {
            setTemplateName(ownTemplate.templateName);
        }

        return () => {
            setTemplateName('');
        };
    }, [ownTemplate]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    };

    return (
        <Modal
            title={isUpdatingTemplate ? 'Update template' : 'Save as template'}
            onClose={closeModal}
            width="530px"
            visible
        >
            <Column gap="24px">
                <Input
                    placeholder="Enter template name here..."
                    title="Template name"
                    value={templateName}
                    onChange={onChange}
                />
                <Row width="100%" justifyEnd gap="16px">
                    <Button onClick={() => closeModal()} variant="secondary">
                        Cancel
                    </Button>
                    <Button disabled={!templateName} onClick={createConfig} variant="primary">
                        {isUpdatingTemplate ? 'Update template' : 'Save template'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
