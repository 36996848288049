import type { Styles } from 'react-modal';

export const content: Styles['content'] = {
    transform: 'translate(-50%, -50%)',
    flexDirection: 'column',
    marginRight: '-50%',
    borderRadius: '8px',
    maxHeight: '85vh',
    maxWidth: '85vw',
    overflow: 'none',
    display: 'flex',
    bottom: 'auto',
    right: 'auto',
    zIndex: 1000,
    padding: '0',
    left: '50%',
    top: '50%'
};

export const overlay = { backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 1000 };
