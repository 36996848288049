import axios, { type AxiosRequestConfig, type AxiosPromise } from 'axios';
import { logoutEvent, $user } from 'stores/user/model';
import { BASE_URL } from 'constants/services';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = BASE_URL;
axiosInstance.defaults.method = 'POST';
axiosInstance.interceptors.response.use(
    config => config.data,
    async config => {
        const status = config?.response?.status;

        if (status === 403 || status === 401) {
            logoutEvent();
        }

        return Promise.reject(config.response);
    }
);

const axiosExport = <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = axiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${$user.getState()?.accessToken}`,
                  ...config.headers
              }
            : {}
    });

    return request as any as Promise<T>;
};

export default axiosExport;
