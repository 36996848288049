import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { PURPLE_100, PURPLE_60, GRAY_100 } from 'ui-kit/GlobalStyle/colors';
import { HorizontalLine } from 'components/common/dividers/HorizontalLine';
import { SimpleButton } from 'components/common/buttons/SimpleButton';
import { Flex } from 'components/wrappers/grid/FlexWrapper';
import { H1, H2 } from 'ui-kit/components/typography/H';
import { P } from 'ui-kit/components/typography/P';
import styled from 'styled-components';
import media from 'utils/media-query';

export const Container = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;
    max-height: 78vh;

    ${media.desktopLg`
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 80px;
        padding-right: 80px;
    `}

    ${media.desktop`
        padding-left: 60px;
        padding-right: 60px;
    `}

    ${media.tablet`
        padding-left: 20px;
        padding-right: 20px;
    `}

    ${media.phone`
        padding-left: 15px;
        padding-right: 15px;
    `}
`;

export const CookieImg = styled(CustomImage)`
    width: 32px;
    height: 32px;
    margin-left: 8px;
    margin-bottom: 20px;

    ${media.desktopLg`
        margin-bottom: 16px;
        width: 24px;
        height: 24px;
    `}

    ${media.desktop`
        margin-bottom: 12px;
        width: 20px;
        height: 20px;
    `}

    ${media.tablet`
        width: 16px;
        height: 16px;
    `}

    ${media.phone`
        width: 12px;
        height: 12px;
    `}
`;

export const PageTitle = styled(H1)`
    margin-bottom: 20px;
    font-size: 42px;
    line-height: 55px;
    font-weight: 700;

    ${media.desktopLg`
        margin-bottom: 16px;
        font-size: 34px;
        line-height: 44px;
    `}

    ${media.desktop`
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 40px;
    `}

    ${media.tablet`
        font-size: 26px;
        line-height: 32px;
    `}

    ${media.phone`
        font-size: 22px;
        line-height: 25px;
    `}
`;

export const ParagraphTitle = styled(H2)`
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 55px;
    font-weight: 700;

    ${media.desktopLg`
        margin-bottom: 16px;
        font-size: 28px;
        line-height: 44px;
    `}

    ${media.desktop`
        margin-bottom: 12px;
        font-size: 26px;
        line-height: 40px;
    `}

    ${media.tablet`
        font-size: 20px;
        line-height: 32px;
    `}

    ${media.phone`
        font-size: 16px;
        line-height: 25px;
    `}
`;

export const Paragraph = styled(P)`
    margin-right: auto;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 35px;
    width: calc(100% - 450px);

    ${media.desktopLg`
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;
        width: calc(100% - 335px);
    `}

    ${media.desktop`
        font-size: 16px;
        line-height: 20px;
        width: calc(100% - 320px);
    `}

    ${media.tablet`
        font-size: 14px;
        line-height: 18px;
        width: 100%;
    `}

    ${media.phone`
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 14px;
    `}
`;

export const SwitchContainer = styled(Flex)`
    align-items: center;

    ${media.tablet`
        margin-bottom: 25px;
    `}
`;

export const SwitchTitle = styled(P)`
    font-weight: 600;
    margin-right: 30px;
    font-size: 24px;
    line-height: 38px;

    ${media.desktopLg`
        margin-right: 25px;
        font-size: 18px;
        line-height: 22px;
    `}

    ${media.desktop`
        margin-right: 20px;
        font-size: 16px;
        line-height: 20px;
    `}

    ${media.tablet`
        margin-right: 8px;
        font-size: 12px;
        line-height: 15px;
    `}
`;

export const ListTitle = styled(P)`
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 35px;

    ${media.desktopLg`
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 28px;
    `}

    ${media.desktop`
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
    `}

    ${media.tablet`
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 18px;
    `}

    ${media.phone`
        font-size: 12px;
        line-height: 14px;
    `}
`;

export const UL = styled.ul`
    padding-left: 22px;
    margin: 0;
    margin-bottom: 40px;

    ${media.desktopLg`
        padding-left: 16px;
        margin-bottom: 30px;
    `}

    ${media.desktop`
        padding-left: 10px;
        margin-bottom: 20px;
    `}

    ${media.tablet`
        padding-left: 16px;
        margin-bottom: 15px;
    `}
`;

export const LI = styled.li`
    color: ${PURPLE_60};
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-left: 0;

    &:last-child {
        margin-bottom: 0;
    }

    ${media.desktopLg`
        font-size: 18px;
        margin-bottom: 8px;
    `}

    ${media.desktop`
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 6px;
    `}

    ${media.tablet`
        font-size: 12px;
        line-height: 25px;
        margin-bottom: 4px;
    `}
`;

export const LP = styled(P)`
    font-size: 24px;
    line-height: 35px;
    color: ${PURPLE_100};

    ${media.desktopLg`
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 8px;
    `}

    ${media.desktop`
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 6px;
    `}

    ${media.tablet`
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 4px;
    `}
`;

export const CustomHorizontalLine = styled(HorizontalLine)`
    margin-bottom: 30px;

    ${media.desktopLg`
        margin-bottom: 20px;
    `}

    ${media.tablet`
        margin-bottom: 12px;
    `}
`;

export const HighlightedText = styled.span`
    color: ${PURPLE_60};
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: ${GRAY_100};
    }
`;

export const Buttons = styled(Flex)`
    justify-content: flex-end;
    ${media.tablet`
        justify-content: space-between;
    `}

    ${media.phone`
        flex-direction: column;
    `}
`;

export const CustomButton = styled(SimpleButton)`
    font-size: 18px;
    line-height: 22px;
    margin-right: 20px;

    ${media.desktopLg`
        margin-right: 16px;
    `}

    ${media.desktop`
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
    `}

    ${media.tablet`
        font-size: 14px;
        line-height: 18px;
        margin-right: 0;
    `}

    ${media.phone`
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        &:first-child {
            margin-bottom: 8px;
        }
    `}
`;
