import type { ReactFCC } from 'types/react';

import { CONTRACT_FORM_ANCHOR_LINK, HOME_LINK } from 'constants/routes';
import { InternalLink } from 'ui-kit/components/links/InternalLink';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Description, BackToHome, Page404, Image } from './styles';
import background404 from './static_assets/404_background.svg';

export const Error404: ReactFCC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToHome = () => navigate(HOME_LINK);

    return (
        <Page404 height={'100vh'} justifyCenter alignCenter>
            <Image alt="404 background" src={background404} />
            <Description>
                {t('errorPages.404.text1')}{' '}
                <InternalLink to={CONTRACT_FORM_ANCHOR_LINK}>{t('errorPages.404.text2')}</InternalLink>{' '}
                {t('errorPages.404.text3')}
            </Description>
            <BackToHome onClick={goToHome} variant="primary">
                {t('errorPages.404.buttonText')}
            </BackToHome>
        </Page404>
    );
};
