import type { CSSProperties } from 'react';

export interface SelectStyles {
    [key: string]: (base: CSSProperties) => CSSProperties;
}

export const customStyles = (width?: string): SelectStyles => ({
    menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 10000 }),
    control: base => ({
        ...base,
        width
    })
});
