import type { PalletProperties } from 'types/preview';

import { type ChangeEvent, useCallback, useState, useMemo, useRef } from 'react';
import { designEvents, designStores } from 'stores/builder/design';
import { ColorPicker } from 'ui-kit/components/ColorPicker';
import { Button } from 'ui-kit/components/buttons/Button';
import { colorPalletModalStore } from 'stores/modals';
import { blockStores } from 'stores/builder/block';
import { Modal } from 'ui-kit/components/Modal';
import { type ReactFCC } from 'types/react';
import { useUnit } from 'effector-react';

import type { ColorPickerProperties } from './types';

import {
    ColorPickerContainer,
    ColorPickerColor,
    InputContainer,
    Container,
    Content,
    Footer,
    Fourth,
    Pallet,
    Second,
    First,
    Input,
    Third,
    Span,
    Text
} from './styles';
import { getColorChoosingText } from './helpers/get-color-choosing-text';
import { COLOR_INPUT_REGEXP } from './constants';
import { isActive } from './helpers/is-active';

type Key = 'second' | 'fourth' | 'first' | 'third';

export const ColorPalletModal: ReactFCC = () => {
    const { closeModal, modal } = colorPalletModalStore;
    const [_, { palletToUpdate }] = useUnit(modal);
    const { activeDesign, themes } = useUnit(designStores.coverStyles);
    const { blocks } = useUnit(blockStores.blocks);
    const ref = useRef<HTMLDivElement>(null);

    const palletKey = `design_pallets_${activeDesign + 1}` as keyof typeof themes;

    const colorPallets = useMemo(() => themes[palletKey], [palletKey, themes]);
    const hasUpfrontSummary = useMemo(() => blocks.some(element => element.component === 'UPFRONT SUMMARY'), [blocks]);

    const [pallet, setPallet] = useState<PalletProperties>(palletToUpdate);
    const [colorPickerProperties, setColorPickerProperties] = useState<ColorPickerProperties>({
        color: 'black',
        from: ''
    });

    const { fourth, second, first, third } = pallet;

    const handleAddPallet = useCallback(
        (pallet: PalletProperties) => {
            const newState = {
                ...themes,
                [palletKey]: themes[palletKey].map((item, index) =>
                    index === themes[palletKey].length - 1 ? { ...pallet, isAdded: true } : item
                )
            };

            designEvents.changeThemes(newState);
            designEvents.changePalletIndex(colorPallets.length - 1);
        },
        [themes, palletKey, colorPallets]
    );

    const handleSetColor = useCallback(
        (key: string, color: string) => {
            setPallet({ ...pallet, [key]: color });
        },
        [pallet]
    );

    const handleSelectPallet = (from: string, color: string) => {
        setColorPickerProperties({ color, from });
    };

    const handleSave = () => {
        handleAddPallet({ fourth, second, first, third });
        closeModal();
    };

    const handleChangeColor = (event: ChangeEvent<HTMLInputElement>) => {
        if (COLOR_INPUT_REGEXP.test(event.target.value)) {
            handleSetColor(colorPickerProperties.from, event.target.value);
        }
    };

    const colorChoosingText = useMemo(() => getColorChoosingText(colorPickerProperties.from), [colorPickerProperties]);

    return (
        <Modal
            customFooter={
                <Footer>
                    <Button onClick={() => closeModal()} variant="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="primary">
                        Save
                    </Button>
                </Footer>
            }
            title="Create a custom color palette"
            onClose={closeModal}
            width="404px"
            visible
        >
            <Container>
                <Text>
                    <Span>{colorChoosingText}</Span>
                </Text>
                <Content ref={ref}>
                    <Pallet>
                        <First
                            style={{
                                border:
                                    pallet.first.length < 1
                                        ? `1px solid ${
                                              isActive('first', colorPickerProperties) ? '#233353' : '#d0d3d9'
                                          }`
                                        : undefined,
                                backgroundColor: first || 'white'
                            }}
                            onClick={() => handleSelectPallet('first', first)}
                        />
                        {activeDesign === 1 && hasUpfrontSummary && (
                            <Second
                                style={{
                                    border:
                                        pallet.second.length < 1
                                            ? `1px solid ${
                                                  isActive('second', colorPickerProperties) ? '#233353' : '#d0d3d9'
                                              }`
                                            : undefined,
                                    backgroundColor: second || 'white'
                                }}
                                onClick={() => handleSelectPallet('second', second)}
                            />
                        )}
                        <Third
                            style={{
                                border:
                                    pallet.third.length < 1
                                        ? `1px solid ${
                                              isActive('third', colorPickerProperties) ? '#233353' : '#d0d3d9'
                                          }`
                                        : undefined,
                                backgroundColor: third || 'white'
                            }}
                            onClick={() => handleSelectPallet('third', third)}
                        />
                        <Fourth
                            style={{
                                border:
                                    pallet.fourth.length < 1
                                        ? `1px solid ${
                                              isActive('fourth', colorPickerProperties) ? '#233353' : '#d0d3d9'
                                          }`
                                        : undefined,
                                backgroundColor: fourth || 'white'
                            }}
                            onClick={() => handleSelectPallet('fourth', fourth)}
                        />
                    </Pallet>
                    <ColorPickerContainer>
                        <ColorPicker
                            onChange={color => {
                                handleSetColor(colorPickerProperties.from, color);
                            }}
                            defaultColor={colorPickerProperties.color}
                        />
                        <InputContainer>
                            <Input
                                defaultValue={pallet[colorPickerProperties.from as Key]}
                                onChange={handleChangeColor}
                            />
                            <ColorPickerColor
                                style={{
                                    backgroundColor: pallet[colorPickerProperties.from as Key] || 'white'
                                }}
                            />
                        </InputContainer>
                    </ColorPickerContainer>
                </Content>
            </Container>
        </Modal>
    );
};
