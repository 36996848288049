import { WHITE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

const a4FormatMixin = css`
    display: flex;
    flex-direction: column;
    min-height: 842px;
    margin-bottom: 30px;
`;

export const A4FormatContainer = styled.div`
    position: relative;
    width: 595px;
`;

export const A4FormatContent_1 = styled.div`
    ${a4FormatMixin}
    background-color: ${WHITE};
    padding: 36px 14px;
`;

export const A4FormatContent_2 = styled.div`
    ${a4FormatMixin}
    background-color: ${WHITE};
    padding: 24px 14px;
    position: relative;
    overflow: hidden;
`;

export const A4FormatContent_3 = styled.div`
    ${a4FormatMixin}
    background-color: ${WHITE};
    padding: 34px 16px;
`;

export const A4FormatContent_4 = styled.div`
    ${a4FormatMixin}
    background-color: ${WHITE};
    padding: 34px 16px;
`;

export const BigCircle = styled.div`
    opacity: 0.1;
    position: absolute;
    left: 120px;
    top: 103px;
`;

export const SmallCircle = styled.div`
    opacity: 0.1;
    position: absolute;
    left: 65px;
    bottom: -110px;
`;

export const Div = styled.div``;
