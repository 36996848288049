import { DraggableTypesEnum } from 'constants/builder/enums';

import type { QuestionnaireItems } from '../types';

export const deleteClauseVariantHelper = (questions: QuestionnaireItems, contentId: string) => {
    const itemsForDeleting = questions.filter(
        item => item.contentId === contentId && item.questionType === DraggableTypesEnum.Field
    );

    return questions.filter(item => !itemsForDeleting.find(question => question.id === item.id));
};
