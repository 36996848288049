import type { BlockInterface, Fields } from 'types/builder/interface';

import { v4 as generateId } from 'uuid';

export const addTimeLineSectionToRowHelper = (groups: BlockInterface['groups'], clause: Fields) => {
    if (groups[0].fields.length > groups[1].fields.length) {
        return [
            {
                ...groups[0]
            },
            {
                fields: [...groups[1].fields, { ...clause, title: '' }],
                id: generateId()
            }
        ];
    } else {
        return [
            {
                fields: [...groups[0].fields, { ...clause, title: '' }],
                id: generateId()
            },
            {
                ...groups[1]
            }
        ];
    }
};
