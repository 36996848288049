import { FieldTypesEnum } from 'constants/builder/enums';
import { createEvent, createStore } from 'effector';

import type { Data } from './types';

import { blockStores } from '../block';

// * events
export const setCurrentFieldsEvent = createEvent<Data[]>();
export const updateFieldEvent = createEvent<{
    contentId: string;
    clauseId: string;
    fieldId: string;
    blockId: string;
    // TODO: any
    newField: any;
}>();

// * stores
export const $currentFields = createStore<Data[]>([]).on(setCurrentFieldsEvent, (_, payload) => payload);
export const $fields = blockStores.blocks.map(({ blocks }) =>
    blocks
        .flatMap(item => item.groups)
        .flatMap(item => item.fields)
        .flatMap(item => item.content)
        .flatMap(item => item.paragraph)
        .filter(item =>
            [
                FieldTypesEnum.MultiSelect,
                FieldTypesEnum.Select,
                FieldTypesEnum.Radio,
                FieldTypesEnum.Date,
                FieldTypesEnum.Text
            ].includes(item.type)
        )
);
