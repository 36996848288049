export enum ActivePreviewDesign {
    Second = 1,
    Fourth = 3,
    First = 0,
    Third = 2
}

export enum ActivePalletIndex {
    Second = 1,
    Fourth = 3,
    First = 0,
    Third = 2
}

// TODO: refactor, change into primary, secondary, darkTextColor, lightTextColor // https://kiaradocs.nifty.pm/tgigk9!iAz6d/task/RTX-935
export interface PalletProperties {
    isAdded?: boolean;
    fourth: string;
    second: string;
    first: string;
    third: string;
}

export interface PreviewThemes {
    design_pallets_1: PalletProperties[];
    design_pallets_2: PalletProperties[];
    design_pallets_3: PalletProperties[];
    design_pallets_4: PalletProperties[];
}

export interface PreviewFonts {
    heading: string;
    body: string;
}

export interface PreviewInfo {
    activeDesign: ActivePreviewDesign;
    palletIndex: ActivePalletIndex;
    documentFonts: PreviewFonts;
    themes: PreviewThemes;
}
