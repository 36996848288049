import styled, { css } from 'styled-components';

interface Props {
    $disabled?: boolean;
    $color?: string;
}

export const StyledLogoImg = styled.div<Props>`
    width: 110px;
    height: 36px;
    cursor: pointer;
    color: ${({ $color }) => $color || '#0014CB'};

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: default;
            pointer-events: none;
        `};
`;
