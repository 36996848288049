import { DraggableTypesEnum } from 'constants/builder/enums';

import type { QuestionnaireItems } from '../types';

export const deleteClauseHelper = (questions: QuestionnaireItems, fieldId: string) => {
    const itemsForDeleting = questions.filter(
        item =>
            item.clauseId === fieldId &&
            (item.questionType === DraggableTypesEnum.Clause || item.questionType === DraggableTypesEnum.Field)
    );

    return questions.filter(item => !itemsForDeleting.find(question => question.id === item.id));
};
