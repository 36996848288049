import { disableDefaultHStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Subtitle = styled.h4`
    ${disableDefaultHStyleMixin};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
