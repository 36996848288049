import type { Content } from 'types/builder/interface';

export const makeIsDefaultHelper = (content: Content[], contentId: string) => {
    const result = [...content];
    const index = result.findIndex(item => item.id === contentId);
    const newResult = result.map((item, i) => (i !== index ? { ...item, show: false } : item));
    const [removed] = newResult.splice(index, 1);
    const [removed2] = newResult.splice(0, 1);
    removed2.isDefault = false;
    removed.isDefault = true;
    removed.show = true;

    newResult.splice(0, 0, removed);
    newResult.splice(index, 0, removed2);

    return newResult;
};
