import { PURPLE_40, PURPLE_60, PURPLE_80, GRAY_60, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled, { css } from 'styled-components';

import type { ButtonVariant } from './index';

const disabledStyles = css`
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
`;

const primaryStyles = css`
    background-color: ${PURPLE_60};
    color: ${WHITE};

    &:hover {
        background-color: ${PURPLE_80};
    }

    &:disabled {
        ${disabledStyles}
        color: ${GRAY_60};
    }
`;

const secondaryStyles = css`
    background-color: ${WHITE};
    border: 1px solid ${PURPLE_60};
    color: ${PURPLE_60};

    &:hover {
        background-color: ${PURPLE_40};
    }

    &:disabled {
        ${disabledStyles}
        background-color: ${WHITE};
    }
`;

export const StyledButton = styled.button<{ variant: ButtonVariant }>`
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 12px 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    ${({ variant }) => variant === 'primary' && primaryStyles}
    ${({ variant }) => variant === 'secondary' && secondaryStyles}
`;
