import { createEvent, createStore, restore } from 'effector';

import { type SetAlertParams, type messageType, type alertType, message } from './types';

const ALERT_LIVE_TIME = 5000;

const messageArray = Object.keys(message) as alertType[];

// * events
const setAlert = createEvent<SetAlertParams>();
const clearAlert = createEvent<number>();
const increment = createEvent();

// * stores
const idStore = createStore(0).on(increment, state => state + 1);

const alertStore = restore<SetAlertParams>(setAlert, {
    id: idStore.getState()
}).on(clearAlert, (state, id) => (id !== state.id ? state : { ...state, active: false }));

const setAlertByType = (message: messageType, type: alertType) => {
    increment();
    const id = idStore.getState();
    setTimeout(() => clearAlert(id), ALERT_LIVE_TIME);
    setAlert({
        active: true,
        message,
        type,
        id
    });
};

for (let key of messageArray) message[key] = (message: messageType) => setAlertByType(message, key);

// * exports
const alertsStores = { alertStore };

export { alertsStores, message };
