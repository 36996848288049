import { createEvent } from 'effector';

// * events
const toggleShowParties = createEvent();
const toggleShowDate = createEvent();
const toggleShowLogo = createEvent();
const onChangeFields = createEvent<{ index: number; value: string }>();

// * exports
export const coverElementEvents = {
    toggleShowParties,
    toggleShowDate,
    toggleShowLogo,
    onChangeFields
};
