import type { ReactFCC } from 'types/react';

import { CookieBanner } from 'components/modals/CookiesModal/CookieBanner';
import { getCookieConsentValue } from 'react-cookie-consent';
import { AppLayout } from 'components/layouts/AppLayout';
import { Preloader } from 'ui-kit/components/Preloader';
import { $currentLanguageCode } from 'stores/language';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { PrivateRoute } from 'routes/PrivateRoute';
import { DASHBOARD_LINK } from 'constants/routes';
import { Routes, Route } from 'react-router-dom';
import { Modal } from 'components/modals/Modal';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { $isLoading } from 'stores/loading';
import { useUnit } from 'effector-react';
import 'stores/init';

import { DASHBOARD_ROUTES, ROUTES } from './constants';

export const App: ReactFCC = () => {
    const [currentLanguageCode, isLoading] = useUnit([$currentLanguageCode, $isLoading]);
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const { i18n: translator } = useTranslation();

    useEffect(() => {
        if (!getCookieConsentValue()) {
            setShowCookieBanner(true);
        }
    }, []);

    useEffect(() => {
        translator.changeLanguage(currentLanguageCode || 'du');
    }, [currentLanguageCode, translator]);

    return (
        <>
            <Preloader isLoading={isLoading} />
            <AppLayout>
                <Modal />
                <Routes>
                    {ROUTES.map(({ element, path }, index) => (
                        <Route element={element} key={index} path={path} />
                    ))}
                    <Route
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                        path={DASHBOARD_LINK}
                    >
                        {DASHBOARD_ROUTES.map(({ element, path }, index) => (
                            <Route element={element} key={index} path={path} />
                        ))}
                    </Route>
                </Routes>
                {showCookieBanner && <CookieBanner />}
            </AppLayout>
        </>
    );
};
