export const getDateFromString = (dateISOString?: string) => {
    if (!dateISOString) return '';

    // * 2021-01-09T13:57:15.832Z -> 2021/01/09
    return dateISOString.split('T')[0].replaceAll('-', '/');
};

export const millisecondsToDays = (milliseconds: number) =>
    // * 166400000 milliseconds -> 1 day
    Math.floor(milliseconds / (1000 * 60 * 1000 * 24));

export const reFormatFromStringToDate = (value?: string) =>
    value ? new Date(value.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')).toString() : new Date().toString();
