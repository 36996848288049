import type { HrProps } from 'components/common/dividers/HorizontalLine/types';

import { hrHeight, hrWidth } from 'components/common/dividers/HorizontalLine/constants';
import { GRAY_40 } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const HorizontalLine = styled.hr<HrProps>`
    width: ${({ width }) => width || hrWidth};
    height: ${hrHeight};
    border: none;
    background-color: ${({ backgroundColor }) => backgroundColor || GRAY_40};
    margin: 0;
    ${({ opacity }) => opacity && `opacity: ${opacity};`};
`;
