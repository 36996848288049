import { $currentLanguageCode, changeLanguageEvent } from 'stores/language';
import { languages } from 'components/Header/constants';
import { useCallback, useMemo } from 'react';
import { useUnit } from 'effector-react';

export const useLanguage = () => {
    const currentLanguageCode = useUnit($currentLanguageCode);

    const setLanguage = useCallback((value: string) => {
        changeLanguageEvent(value);
    }, []);

    const language = useMemo(() => languages.find(({ value }) => value === currentLanguageCode), [currentLanguageCode]);

    return { setLanguage, language };
};
