import {
    LazyLoadedThankYouRegistration,
    LazyLoadedPasswordChanged,
    LazyLoadedThankYouContact,
    LazyLoadedForgotPassword,
    LazyLoadedResetPassword,
    LazyLoadedPasswordReset,
    LazyLoadedJoinToCompany,
    LazyLoadedPrivacyPolicy,
    LazyLoadedVerifyAccount,
    LazyLoadedMollieSuccess,
    LazyLoadedConstructor,
    LazyLoadedSocialMedia,
    LazyLoadedCheckEmail,
    LazyLoadedTermsOfUse,
    LazyLoadedDocuments,
    LazyLoadedTemplates,
    LazyLoadedRegister,
    LazyLoadedSettings,
    LazyLoadedBuilder,
    LazyLoadedDrafts,
    LazyLoadedPrices,
    LazyLoadedLogin,
    LazyLoadedMagic,
    LazyLoadedMain
} from 'pages';
import {
    THANK_YOU_REGISTRATION_LINK,
    THANK_YOU_CONTACT_LINK,
    FORGOT_PASSWORD_LINK,
    JOIN_TO_COMPANY_LINK,
    PRIVACY_POLICY_LINK,
    VERIFY_ACCOUNT_LINK,
    RESET_PASSWORD_LINK,
    SOCIAL_MEDIA_LINK,
    CHECK_EMAIL_LINK,
    CONSTRUCTOR_LINK,
    TERM_OF_USE_LINK,
    PASSWORD_CHANGED,
    DOCUMENTS_LINK,
    TEMPLATES_LINK,
    PASSWORD_RESET,
    MOLLIE_SUCCESS,
    REGISTER_LINK,
    SETTINGS_LINK,
    BUILDER_LINK,
    PRICES_LINK,
    DRAFTS_LINK,
    MAGIC_LINK,
    AUTH_LINK,
    HOME_LINK
} from 'constants/routes';
import SuspenseWrapper from 'components/wrappers/SuspenseWrapper';
import { PrivateRoute } from 'routes/PrivateRoute';
import { PublicRoute } from 'routes/PublicRoute';
import { Error404 } from 'pages/404';

export const ROUTES = [
    {
        element: (
            <PublicRoute>
                <SuspenseWrapper>
                    <LazyLoadedRegister />
                </SuspenseWrapper>
            </PublicRoute>
        ),
        path: REGISTER_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedLogin />
            </SuspenseWrapper>
        ),
        path: AUTH_LINK
    },
    {
        element: (
            <PublicRoute>
                <SuspenseWrapper>
                    <LazyLoadedForgotPassword />
                </SuspenseWrapper>
            </PublicRoute>
        ),
        path: FORGOT_PASSWORD_LINK
    },
    {
        element: (
            <PublicRoute>
                <SuspenseWrapper>
                    <LazyLoadedJoinToCompany />
                </SuspenseWrapper>
            </PublicRoute>
        ),
        path: JOIN_TO_COMPANY_LINK
    },
    {
        element: (
            <PublicRoute>
                <SuspenseWrapper>
                    <LazyLoadedCheckEmail />
                </SuspenseWrapper>
            </PublicRoute>
        ),
        path: CHECK_EMAIL_LINK
    },
    {
        element: (
            <PublicRoute>
                <SuspenseWrapper>
                    <LazyLoadedResetPassword />
                </SuspenseWrapper>
            </PublicRoute>
        ),
        path: RESET_PASSWORD_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedMain />
            </SuspenseWrapper>
        ),
        path: HOME_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedSocialMedia />
            </SuspenseWrapper>
        ),
        path: SOCIAL_MEDIA_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedPrices />
            </SuspenseWrapper>
        ),
        path: PRICES_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedPrivacyPolicy />
            </SuspenseWrapper>
        ),
        path: PRIVACY_POLICY_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedTermsOfUse />
            </SuspenseWrapper>
        ),
        path: TERM_OF_USE_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedConstructor />
            </SuspenseWrapper>
        ),
        path: CONSTRUCTOR_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedThankYouContact />
            </SuspenseWrapper>
        ),
        path: THANK_YOU_CONTACT_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedThankYouRegistration />
            </SuspenseWrapper>
        ),
        path: THANK_YOU_REGISTRATION_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedVerifyAccount />
            </SuspenseWrapper>
        ),
        path: VERIFY_ACCOUNT_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedPasswordReset />
            </SuspenseWrapper>
        ),
        path: PASSWORD_RESET
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedPasswordChanged />
            </SuspenseWrapper>
        ),
        path: PASSWORD_CHANGED
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedMollieSuccess />
            </SuspenseWrapper>
        ),
        path: MOLLIE_SUCCESS
    },
    {
        element: (
            <PrivateRoute>
                <SuspenseWrapper>
                    <LazyLoadedBuilder />
                </SuspenseWrapper>
            </PrivateRoute>
        ),
        path: BUILDER_LINK
    },
    {
        element: (
            <PrivateRoute>
                <SuspenseWrapper>
                    <LazyLoadedMagic />
                </SuspenseWrapper>
            </PrivateRoute>
        ),
        path: MAGIC_LINK
    },
    {
        element: <Error404 />,
        path: '*'
    }
];

export const DASHBOARD_ROUTES = [
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedDocuments />
            </SuspenseWrapper>
        ),
        path: DOCUMENTS_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedDrafts />
            </SuspenseWrapper>
        ),
        path: DRAFTS_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedTemplates />
            </SuspenseWrapper>
        ),
        path: TEMPLATES_LINK
    },
    {
        element: (
            <SuspenseWrapper>
                <LazyLoadedSettings />
            </SuspenseWrapper>
        ),
        path: SETTINGS_LINK
    }
];
