export enum EditorLocationEnum {
    Clause = 'clause',
    Field = 'field',
    Block = 'block'
}

export enum FieldTypesEnum {
    MultiSelect = 'MultiSelect',
    JustText = 'JustText',
    Select = 'Dropdown',
    Radio = 'Radio',
    Text = 'Text',
    Date = 'Date'
}

export enum DraggableTypesEnum {
    Subject = 'SUBJECT',
    Clause = 'CLAUSE',
    Field = 'FIELD',
    Block = 'BLOCK'
}
