import { GRAY_20, WHITE } from 'ui-kit/GlobalStyle/colors';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: ${WHITE};
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid ${GRAY_20};
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 16px;
`;
