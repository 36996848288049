import { EditorLocationEnum } from 'constants/builder/enums';
import { createEvent, restore } from 'effector';
import { EditorState } from 'draft-js';

// * events
const setLocation = createEvent<EditorLocationEnum>();
const setEditorState = createEvent<EditorState>();

// * stores
const location = restore<EditorLocationEnum>(setLocation, EditorLocationEnum.Field);

const editorState = restore<EditorState>(setEditorState, EditorState.createEmpty());

// * exports
export const editorEvents = {
    setEditorState,
    setLocation
};

export const editorStores = {
    editorState,
    location
};
