import type { TFunction } from 'i18next';

import { DOCUMENTS_LINK, TEMPLATES_LINK, SETTINGS_LINK, DRAFTS_LINK } from 'constants/routes';
import { type ReactNode } from 'react';

import { TemplateIcon, DocumentIcon, SettingIcon, DraftIcon } from './static_assets';

export interface LinkProps {
    disabled?: boolean;
    icon: ReactNode;
    text: string;
    href: string;
}

export const createLinks = (t: TFunction<'translation', undefined>): LinkProps[] => [
    { text: t('dashboard.aside.documents'), icon: <DocumentIcon />, href: DOCUMENTS_LINK },
    { text: t('dashboard.aside.templates'), icon: <TemplateIcon />, href: TEMPLATES_LINK },
    { text: t('dashboard.aside.drafts'), icon: <DraftIcon />, href: DRAFTS_LINK },
    { text: t('dashboard.aside.settings'), icon: <SettingIcon />, href: SETTINGS_LINK }
];

export const getActiveClassName = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '');
