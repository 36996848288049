import type { ReactFCC } from 'types/react';

import { DescriptionBadge } from 'components/common/DescriptionBadge';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { format } from 'date-fns';

import { DatePickerContainer, PlaceholderSpan } from './styles';
import { InputTitle } from '../typography/Span';
import { getValidDate } from './utils';

export interface DatePickerProps {
    onChange?: (date: string) => void;
    dateRange?: [string, string];
    defaultDate?: string;
    description?: string;
    placeholder?: string;
    titleColor?: string;
    disabled?: boolean;
    subtitle?: string;
    title?: string;
}

export const DatePicker: ReactFCC<DatePickerProps> = ({
    defaultDate = '',
    placeholder = '',
    description,
    titleColor,
    dateRange,
    onChange,
    disabled,
    title
}) => {
    const [isCalendarOpened, updateIsCalendarOpened] = useToggle(false);

    const [date, setDate] = useState<undefined | Date | null>(null);

    const onInputClick = () => updateIsCalendarOpened();
    const onClickOutside = () => updateIsCalendarOpened();

    const onChangeDate = (date: Date) => {
        setDate(date);
        onChange?.(format(date, 'dd/MM/yyyy'));
        updateIsCalendarOpened();
    };

    useEffect(() => {
        if (!defaultDate) {
            return;
        }
        setDate(getValidDate(defaultDate));
    }, [defaultDate]);

    return (
        <Column width="100%">
            <InputTitle color={titleColor}>
                {title} {description && <DescriptionBadge description={description} />}
            </InputTitle>
            <ReactDatePicker
                customInput={
                    <DatePickerContainer>
                        {date ? (
                            format(date, 'dd/MM/yyyy')
                        ) : defaultDate ? (
                            defaultDate
                        ) : (
                            <PlaceholderSpan>{placeholder}</PlaceholderSpan>
                        )}
                    </DatePickerContainer>
                }
                maxDate={dateRange?.[1] ? new Date(dateRange[1]) : undefined}
                minDate={dateRange?.[0] ? new Date(dateRange[0]) : undefined}
                wrapperClassName="date_picker full-width"
                open={!disabled && isCalendarOpened}
                onClickOutside={onClickOutside}
                onInputClick={onInputClick}
                popperPlacement="bottom"
                onChange={onChangeDate}
                disabled={disabled}
                selected={date}
            />
        </Column>
    );
};
